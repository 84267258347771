import styled, { css } from 'styled-components';
import { colors } from '../../../constants/theme';
import {
  MAIN_DIRECTION_ANIMATION_DURATION,
  SHOW_CONTENT_ANIMATION_DURATION,
} from './styleConstants.js';
const BUBBLE_RADIUS = '7px';

export const InfoTooltip = styled.div`
  position: absolute;
  width: 0px;
  top: ${props => props.top}%;
  left: ${props => props.left}%;
`;

export const DIRECTIONS__INFO_TOOLTIP_HORISONTAL_LINE = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const TEXT_POSITION__INFO_TOOLTIP_HORISONTAL_LINE = {
  BOTTOM: 'bottom',
  TOP: 'top',
};

export const InfoTooltipBubble = styled.div`
  position: absolute;
`;

export const leftDirection = css`
  left: ${props => (props.isShowContetnt ? -props.length : 0)}px;

  ${props =>
    props.isShowContetnt
      ? `transition: width ${MAIN_DIRECTION_ANIMATION_DURATION}ms ease-in-out, left ${MAIN_DIRECTION_ANIMATION_DURATION}ms ease-in-out`
      : `transition: width ${MAIN_DIRECTION_ANIMATION_DURATION}ms ease-in-out ${SHOW_CONTENT_ANIMATION_DURATION}ms, left ${MAIN_DIRECTION_ANIMATION_DURATION}ms ease-in-out ${SHOW_CONTENT_ANIMATION_DURATION}ms`};
  transition-delay: ${props => props.delay + 'ms'};
`;

export const rightDirection = css`
  text-align: end;

  ${props =>
    props.isShowContetnt
      ? `transition: width ${MAIN_DIRECTION_ANIMATION_DURATION}ms ease-in-out ${
          props.delay
        }ms`
      : `transition: width ${MAIN_DIRECTION_ANIMATION_DURATION}ms ease-in-out ${SHOW_CONTENT_ANIMATION_DURATION}ms`};
`;

export const textBottom = css`
  top: ${BUBBLE_RADIUS};
  border-top: 1px solid ${colors.secondaryGray3};
`;

export const textTop = css`
  bottom: -${BUBBLE_RADIUS};
  border-bottom: 1px solid ${colors.secondaryGray3};
`;

export const InfoTooltipBorders = styled.div`
  position: absolute;
  width: ${props => (props.isShowContetnt ? props.length : 0)}px;

  ${({ direction }) => {
    switch (direction) {
      case DIRECTIONS__INFO_TOOLTIP_HORISONTAL_LINE.LEFT:
        return leftDirection;
      case DIRECTIONS__INFO_TOOLTIP_HORISONTAL_LINE.RIGHT:
        return rightDirection;
    }
  }}

  ${({ textPosition }) => {
    switch (textPosition) {
      case TEXT_POSITION__INFO_TOOLTIP_HORISONTAL_LINE.TOP:
        return textTop;
      case TEXT_POSITION__INFO_TOOLTIP_HORISONTAL_LINE.BOTTOM:
        return textBottom;
    }
  }}
`;
export const InfoTooltipContent = styled.div`
  padding: 1em 0.5em;
  opacity: ${props => (props.isShowContetnt ? 1 : 0)};

  ${props =>
    props.isShowContetnt
      ? `transition: opacity ${SHOW_CONTENT_ANIMATION_DURATION}ms cubic-bezier(0.64, 0.04, 0.35, 1) ${props.delay +
          MAIN_DIRECTION_ANIMATION_DURATION}ms`
      : `transition: opacity ${SHOW_CONTENT_ANIMATION_DURATION}ms cubic-bezier(0.64, 0.04, 0.35, 1)`};
`;
