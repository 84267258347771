import React, { Component } from 'react';
import {
  CardListWrapperCss,
  CardWrapperCss,
  CardImageCss,
  CardContentCss,
  CardDateWrapperCss,
  CardDateCss,
  CardTitleCss,
  CardLocationCss,
  CardDescriptionCss,
  MobileHeightCss,
} from './index.css';
import Button from '../Button';
import InfoBubble from '../InfoBubble';

class CardList extends Component {
  state = {
    current: 0,
    initialX: 0,
  };
  isCurrent = index => {
    const { current } = this.state;

    return current === index;
  };
  isNext = index => {
    const { current } = this.state;
    const { list } = this.props;
    let nextIndex = current + 1;
    if (nextIndex === list.length) {
      nextIndex = 0;
    }
    return nextIndex === index;
  };
  isPrev = index => {
    const { current } = this.state;
    const { list } = this.props;
    let prevIndex = current - 1;
    if (prevIndex < 0) {
      prevIndex = list.length - 1;
    }
    return prevIndex === index;
  };

  handleMouseDown = e => {
    this.setState({ initialX: e.clientX });
  };

  handleMouseUp = e => {
    const { list } = this.props;
    const { initialX, current } = this.state;
    const x = e.clientX;
    let newActive = current;

    if (x === initialX) {
      if (typeof window === 'undefined') {
        return;
      }

      const middlePoint = window.innerWidth / 2;

      // Checks if the click is in the right or the left side of the container
      if (x < middlePoint) {
        newActive = newActive - 1 < 0 ? list.length - 1 : newActive - 1;
      } else {
        newActive = newActive + 1 === list.length ? 0 : newActive + 1;
      }
    } else if (x < initialX && initialX - x > 75) {
      newActive = newActive + 1 === list.length ? 0 : newActive + 1;
    } else if (x > initialX && x - initialX > 75) {
      newActive = newActive - 1 < 0 ? list.length - 1 : newActive - 1;
    }

    this.setState({ initialX: 0, current: newActive });
  };

  handleTouchStart = e => {
    this.setState({ initialX: e.touches[0].clientX });
  };

  handleTouchEnd = e => {
    const { list } = this.props;
    const { initialX, current } = this.state;
    const x = e.changedTouches[0].clientX;
    let newActive = current;
    if (x === initialX) {
      if (typeof window === 'undefined') {
        return;
      }
      const middlePoint = window.innerWidth / 2;

      if (x < middlePoint) {
        newActive = newActive - 1 < 0 ? list.length - 1 : newActive - 1;
      } else {
        newActive = newActive + 1 === list.length ? 0 : newActive + 1;
      }
    } else if (x < initialX && initialX - x > 75) {
      newActive = newActive + 1 === list.length ? 0 : newActive + 1;
    } else if (x > initialX && x - initialX > 75) {
      newActive = newActive - 1 < 0 ? list.length - 1 : newActive - 1;
    }

    this.setState({ initialX: 0, current: newActive });
  };

  getDateInfo = dateInfo => {
    let string = '';
    dateInfo.forEach(info => {
      string = `${string}${string ? '\n' : ''}${
        info.infoDate
      }\n${info.infoPlace || ''}\n`;
    });
    return string;
  };

  preventClick = e => {
    e.stopPropagation();
  };

  renderItem = (item, index) => {
    const { date, name, location, description, link, image, dateInfo } = item;
    return (
      <CardWrapperCss
        key={index}
        id={index}
        isCurrent={this.isCurrent(index)}
        isPrev={this.isPrev(index)}
        isNext={this.isNext(index)}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
      >
        <CardImageCss>
          <img src={image} />
        </CardImageCss>
        <CardContentCss>
          <div>
            <CardDateWrapperCss>
              <CardDateCss>{date}</CardDateCss>
              {dateInfo && (
                <InfoBubble
                  type="info"
                  text={this.getDateInfo(dateInfo)}
                  onClick={this.preventClick}
                  onTouchStart={this.preventClick}
                  onTouchEnd={this.preventClick}
                />
              )}
            </CardDateWrapperCss>

            <CardTitleCss>{name}</CardTitleCss>
            <CardLocationCss>{location}</CardLocationCss>
            <CardDescriptionCss>{description}</CardDescriptionCss>
          </div>
          <a
            href={link}
            target="_blank"
            onClick={this.preventClick}
            onTouchStart={this.preventClick}
            onTouchEnd={this.preventClick}
          >
            <Button>more info</Button>
          </a>
        </CardContentCss>
      </CardWrapperCss>
    );
  };

  next = () => {
    const { current } = this.state;
    const { list } = this.props;
    this.setState({ current: current + 1 === list.length ? 0 : current + 1 });
  };
  prev = () => {
    const { current } = this.state;
    const { list } = this.props;
    this.setState({ current: current - 1 < 0 ? list.length - 1 : current - 1 });
  };

  render() {
    const { list } = this.props;

    return (
      <CardListWrapperCss>
        <MobileHeightCss>
          {list && this.renderItem(list[0], -1)}
        </MobileHeightCss>
        {list &&
          list.map((item, index) => {
            return this.renderItem(item, index);
          })}
      </CardListWrapperCss>
    );
  }
}

export default CardList;
