import React, { useState } from "react";
import styled from "styled-components";

export const HeaderCss = styled.div`
  background-color: rgba(223, 220, 220);

  border-top: 10px solid #8a7c9f;
  position: fixed;
  top: 0;
  line-height: 23px;
  font-weight: 500;
  top: 0;
  /* background-color: #fff; */
  width: 100%;
  box-shadow: none;
  z-index: 99999;
  display: block;

  @media (max-width: 980px) {
    position: absolute;
  }

  .logo_container {
    position: absolute;
    height: 100%;
    width: 100%;
    @media (max-width: 980px) {
      width: 80%;
    }
  }
  span.logo_helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
  }
  #logo {
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    margin-bottom: 0;
    max-height: 54%;
    display: inline-block;
    float: none;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    max-height: 45%;
  }
  #et-top-navigation {
    font-weight: 600;
    padding-top: 33px;
    padding-left: 208px;
    float: right;

    @media (min-width: 981px) {
      padding: 30px 0 0 0;
    }

    @media (max-width: 980px) {
      padding-top: 24px;
      display: block;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
    background: 0 0;
    list-style: none;
  }
  #top-menu-nav {
    line-height: 0;
    display: block;
    float: left;
  }

  #top-menu,
  #top-menu-nav {
    line-height: 0;
  }
  #top-menu,
  .et-menu,
  nav#top-menu-nav,
  nav.et-menu-nav {
    float: left;
  }
  #top-menu li {
    margin: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
    background: 0 0;
    display: inline-block;
    font-size: 14px;
    padding-right: 22px;
    line-height: 14px;
    position: relative;
  }
  #top-menu li a {
    font-family: "Avenir Medium", Helvetica, Arial, Lucida, sans-serif;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    vertical-align: baseline;
    background: 0 0;
    font-size: 16px;
    text-decoration: none;
    display: block;
    position: relative;
    padding-bottom: 30px;
    color: rgba(0, 0, 0, 0.95) !important;
    font-weight: 300;
  }
  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  #top-menu {
    @media (max-width: 980px) {
      display: none;
      overflow: none;
    }
  }

  #et_mobile_nav_menu {
    float: right;
    display: none;

    @media (max-width: 980px) {
      display: block;
    }
  }
  .mobile_nav .select_page {
    display: none;
  }
  #et-top-navigation .mobile_menu_bar,
  .et_header_style_split #et-top-navigation .mobile_menu_bar {
    padding-bottom: 24px;
  }

  .burger {
    color: #2ea3f2;
    width: 32px;
    font-size: 24px;
    padding-bottom: 24px;
  }

  #main-header .et_mobile_menu {
    background-color: rgba(223, 220, 220, 0.5);
  }

  .container {
    width: 90%;
    position: relative;
  }

  .et_mobile_menu {
    position: absolute;
    left: 0;
    padding: 5%;
    background: #fff;
    width: 100%;
    visibility: visible;
    opacity: 1;
    display: none;

    z-index: 9999;
    border-top: 3px solid #2ea3f2;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(223, 220, 220, 0.8);

    &.open {
      display: block;
    }
  }
  .et_mobile_menu li a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    color: #666;
    padding: 10px 5%;
    display: block;
    color: rgba(0, 0, 0, 0.8);
  }
`;

const Header = () => {
  const [visible, setVisible] = useState(false);
  return (
    <HeaderCss>
      <div className="container clearfix et_menu_container">
        <div className="logo_container">
          <span className="logo_helper"></span>
          <a href="https://medicairhealth.co.uk/">
            <img
              src="https://medicair.webdezign.uk/wp-content/uploads/2020/06/MedicAir-Logo-final.png"
              alt="MedicAir®"
              id="logo"
            />
          </a>
        </div>

        <div id="et-top-navigation" style={{ paddingLeft: "208px" }}>
          <nav id="top-menu-nav">
            <ul id="top-menu" className="nav">
              <li
                id="menu-item-1107"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-1107"
              >
                <a href="https://medicairhealth.co.uk" aria-current="page">
                  Home
                </a>
              </li>
              <li
                id="menu-item-458"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-458"
              >
                <a href="https://medicairhealth.co.uk/#protection">
                  Protection
                </a>
              </li>
              <li
                id="menu-item-460"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-460"
              >
                <a
                  href="https://medicairhealth.co.uk/#technology"
                  aria-current="page"
                >
                  Technology
                </a>
              </li>
              <li
                id="menu-item-459"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-459"
              >
                <a
                  href="https://medicairhealth.co.uk/#control"
                  aria-current="page"
                >
                  Control
                </a>
              </li>
              <li
                id="menu-item-461"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-461"
              >
                <a
                  href="https://medicairhealth.co.uk/#about"
                  aria-current="page"
                >
                  About us
                </a>
              </li>
            </ul>
          </nav>
          <div id="et_mobile_nav_menu">
            <div class="mobile_nav closed">
              <span className="select_page">Select Page</span>
              <div className="burger" onClick={() => setVisible(!visible)}>
                <i class="fas fa-bars"></i>
              </div>
              <ul
                id="mobile_menu"
                className={`et_mobile_menu ${visible ? "open" : "close"}`}
              >
                <li
                  id="menu-item-1107"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-1107 et_first_mobile_item"
                >
                  <a href="https://medicairhealth.co.uk" aria-current="page">
                    Home
                  </a>
                </li>
                <li
                  id="menu-item-458"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-458"
                >
                  <a
                    href="https://medicairhealth.co.uk/#protection"
                    aria-current="page"
                    data-et-has-event-already="true"
                  >
                    Protection
                  </a>
                </li>
                <li
                  id="menu-item-460"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-460"
                >
                  <a
                    href="https://medicairhealth.co.uk/#technology"
                    aria-current="page"
                    data-et-has-event-already="true"
                  >
                    Technology
                  </a>
                </li>
                <li
                  id="menu-item-459"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-459"
                >
                  <a
                    href="https://medicairhealth.co.uk/#control"
                    aria-current="page"
                    data-et-has-event-already="true"
                  >
                    Control
                  </a>
                </li>
                <li
                  id="menu-item-461"
                  className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-461"
                >
                  <a
                    href="https://medicairhealth.co.uk/#about"
                    aria-current="page"
                    data-et-has-event-already="true"
                  >
                    About us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </HeaderCss>
  );
};

export default Header;
