import styled from 'styled-components';
import { colors, transition } from '../../../../constants/theme';

export const PaymentFormWrapperCss = styled.div`
  left: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'default' : 'none')};
  position: ${props => (props.active ? 'relative' : 'absolute')};
  top: 0;
  transition: all 0.5s ${transition};
  width: 100%;
`;
