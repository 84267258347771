import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { CartComponent, CheckoutComponent } from "./components/Checkout";

import styled from "styled-components";
import { screens, fonts } from "./constants/theme";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <LayoutsWrapperCss>
          <Header />
          <Route exact path="/">
            <CartComponent />
          </Route>
          <Route exact path="/checkout">
            <CheckoutComponent />
          </Route>
          <Footer />
        </LayoutsWrapperCss>
      </Switch>
    </Router>
  );
}

export default App;

const LayoutsWrapperCss = styled.div`
  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  button {
    font-family: ${fonts.primary};
  }

  h1 {
    font-weight: 300;
    font-size: 68px;
    line-height: 78px;

    @media (max-width: 1366px) {
      font-size: 62px;
      line-height: 70px;
    }
    @media (max-width: ${screens.lg}) {
      font-size: 48px;
      line-height: 50px;
    }
  }

  h2 {
    font-weight: 300;
    font-size: 52px;
    line-height: 58px;

    @media (max-width: ${screens.lg}) {
      font-size: 40px;
      line-height: 42px;
    }
  }

  h3 {
    font-weight: 300;
    font-size: 40px;
    line-height: 40px;
    @media (max-width: ${screens.lg}) {
      font-size: 36px;
      line-height: 38px;
    }
  }

  h4 {
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    @media (max-width: ${screens.lg}) {
      font-size: 26px;
      line-height: 30px;
    }
  }

  h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    @media (max-width: ${screens.lg}) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  h6 {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    @media (max-width: ${screens.lg}) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  p {
    font-weight: 300;
    font-size: 21px;
    line-height: 28px;
    @media (max-width: 1366px) {
      font-size: 18px;
      line-height: 26px;
    }
    @media (max-width: ${screens.lg}) {
      font-size: 19px;
      line-height: 24px;
    }
  }
`;
