import React, { Component } from 'react';
import MobxReactForm from 'mobx-react-form';
import { fields, plugins } from './loginFormSetup';
import {
  LoginFormWrapperCss,
  ForgotButtonCss,
  ButtonWrapperCss,
  ErrorCss,
} from './index.css';
import InputText from '../../InputText';
import { Button } from '../../../../Shared';

// Mobx setup
const form = new MobxReactForm({ fields }, { plugins });

class LoginForm extends Component {
  handleLogin = () => {
    const {
      store: { login },
      switchShippingView,
    } = this.props;
    form.submit({
      onSuccess: async fieldset => {
        const { email, password } = fieldset.values();

        const loginAnswer = await login(email, password);
        if (loginAnswer === 'error') return;
        switchShippingView();
      },
      onError: fieldset => {
        // TODO Scroll to the first field with error !
        console.log('wof', fieldset.errors());
      },
    });
  };
  render() {
    const {
      selectView,
      store: { loginError, loadingButton },
    } = this.props;
    return (
      <LoginFormWrapperCss>
        <InputText
          field={form.$('email')}
          handleOnChange={this.handleChangeField}
          req
        />
        <InputText
          type="password"
          field={form.$('password')}
          handleOnChange={this.handleChangeField}
          req
        />
        {loginError && <ErrorCss>{loginError}</ErrorCss>}
        <ButtonWrapperCss>
          <Button
            type="primary"
            revert
            onClick={this.handleLogin}
            loading={loadingButton}
          >
            sign in
          </Button>
          <ForgotButtonCss onClick={() => selectView('forgot')}>
            Forgot password?
          </ForgotButtonCss>
        </ButtonWrapperCss>
      </LoginFormWrapperCss>
    );
  }
}

export default LoginForm;
