import validatorjs from 'validatorjs';

const plugins = { dvr: validatorjs };

const fields = [
  {
    name: 'password',
    placeholder: 'password',
    rules: 'required',
  },
  {
    name: 'confirmPassword',
    placeholder: 'confirm password',
    rules: 'required|same:password',
  },
];

export { plugins, fields };
