import React, { Component } from 'react';

import withContext from '../../../helpers/withContext';
import { SectionWrapper } from '../../Shared';
import AddressForm from './AddressForm';
import CartPreview from './CartPreview';
import ChooseDelivery from './ChooseDelivery';
import GuestCheckout from './GuestCheckout';
import AccountForm from './AccountForm';
import PaymentForm from './PaymentForm';
import ReviewOrder from './ReviewOrder';
import ConfirmView from './ConfirmView';
import {
	CheckoutWrapperCss,
	CheckoutInnerCss,
	StepIndicatorCss,
	StepCss,
	StepNameCss,
	LineCss,
	ContentCss,
	LeftSideCss,
	RightSideCss,
} from './index.css';

const COUNTRY = 'country';
const ZONE = 'zone';

class Checkout extends Component {
	constructor(props) {
		super(props);
		const { recurringTotal, isLoggedIn } = props.store;
		const showLogin = !isLoggedIn && (recurringTotal || recurringTotal !== 0);

		this.state = {
			activeLeftView: showLogin ? 'login' : 'shipping',
			activeRightView: showLogin ? 'login' : 'cartPreview',
			billing: null,
			confirm: false,
			delivery: 'delivery',
			isLoggedIn: null,
			lastStep: 0,
			pickDelivery: false,
			review: false,
			sameAddress: true,
			shipping: null,
			step: 0,
			cartChanged: null,
		};
	}

	static getDerivedStateFromProps(props, state) {
		const { recurringTotal, isLoggedIn } = props.store;
		const showLogin = !isLoggedIn && recurringTotal;
		if (props.store.isLoggedIn !== state.isLoggedIn) {
			return {
				activeLeftView: showLogin ? 'login' : 'shipping',
				activeRightView: showLogin ? 'login' : 'cartPreview',
				isLoggedIn,
			};
		}

		// if (props.store.cartChanged !== state.cartChanged) {
		//   return {
		//     cartChanged: props.store.cartChanged,
		//     activeLeftView: showLogin ? 'login' : 'shipping',
		//     activeRightView: showLogin ? 'login' : 'cartPreview',
		//     review: false,
		//     pickDelivery: false,
		//     confirm: false,
		//   };
		// }
	}

	changeSameAddress = (value) => this.setState({ sameAddress: value });

	changeStep = (step) => {
		// TODO not for now, need to detect what was prev left/right view
		// Handles shipping / payment steps
		// const { lastStep } = this.state;
		// if (step <= lastStep) {
		//   this.setState({ step });
		// }
	};

	switchShippingView = () => {
		this.scrollTop();
		this.setState({
			activeLeftView: 'shipping',
			activeRightView: 'cartPreview',
			review: false,
			pickDelivery: false,
			confirm: false,
		});
	};

	switchPaymentView = () => {
		this.scrollTop();
		this.setState({
			activeLeftView: 'payment',
			activeRightView: 'cartPreview',
			review: false,
			pickDelivery: false,
			confirm: false,
			step: 1,
			lastStep: 1,
		});
	};

	switchLoginView = () => {
		this.scrollTop();
		this.setState({
			activeLeftView: 'login',
			activeRightView: 'login',
			review: false,
			pickDelivery: false,
			confirm: false,
		});
	};

	scrollTop = () => {
		if (typeof window !== 'undefined') {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}
	};

	changeDelivery = (delivery) => {
		const { formValues, shipping, billing } = this.state;
		this.setState({ delivery }, () => {
			// Wait to set delivery to state than call function
			this.scrollTop();

			if (formValues) {
				this.handleSubmitSuccess(formValues);
			} else {
				this.handleSubmitExisting(shipping, billing);
			}
		});
	};

	onSubmit = (values) => {
		// let isIshields = this.checkIfCartHasProduct("iShields");
		// if (values.country === "united kingdom" && !isIshields) {
		//   this.scrollTop();
		//   this.setState({ pickDelivery: true, formValues: values });
		// } else {
		//   this.handleSubmitSuccess(values);
		// }
		this.handleSubmitSuccess(values);
	};

	onSubmitExisting = (shipping, billing) => {
		// let isIshields = this.checkIfCartHasProduct('iShields');
		// if (shipping.country.toLowerCase() === 'united kingdom' && !isIshields) {
		// 	this.setState({
		// 		pickDelivery: true,
		// 		formValues: null,
		// 		shipping,
		// 		billing,
		// 	});
		// 	this.scrollTop();
		// } else {
		// 	this.handleSubmitExisting(shipping, billing);
		// }
		this.handleSubmitExisting(shipping, billing);
	};

	handleSubmitExisting = async (shipping, billing) => {
		const {
			setPaymentAddressExisting,
			setShippingAddressExisting,
			getShippingMethods,
			getPaymentMethods,
		} = this.props.store;
		const { sameAddress, delivery } = this.state;

		if (sameAddress) {
			const paymentAnswer = await setPaymentAddressExisting(shipping.address_id);
			if (paymentAnswer === 'error') return;
			const shippingAnswer = await setShippingAddressExisting(shipping.address_id);
			if (shippingAnswer === 'error') return;
		} else {
			const paymentAnswer = await setPaymentAddressExisting(billing.address_id);
			if (paymentAnswer === 'error') return;
			const shippingAnswer = await setShippingAddressExisting(shipping.address_id);
			if (shippingAnswer === 'error') return;
		}

		// Get and Set shipping methods
		const getShippingMethodsAnswer = await getShippingMethods(delivery);
		if (getShippingMethodsAnswer === 'error') return;

		const paymentAnswer = await getPaymentMethods();
		if (paymentAnswer === 'error') return;

		// Set view for payment
		this.switchPaymentView();
	};

	findValue = (type, value) => {
		let found = null;
		if (type === COUNTRY) {
			const { countries } = this.props.store;
			if (!countries) return null;
			const item = countries.filter(
				(country) => country.name.toLowerCase() === value.toLowerCase()
			);
			found = item.length > 0 && item[0].country_id;
		} else if (type === ZONE) {
			const { zones } = this.props.store;
			if (!zones) return null;
			const item = zones.filter((zone) => zone.name.toLowerCase() === value.toLowerCase());
			found = item.length > 0 && item[0].zone_id;
		}

		return found;
	};

	checkIfCartHasProduct = (product) => {
		const {
			props: {
				store: { cart },
			},
		} = this;
		let Contains = cart.products.filter((item) => item.name === product);
		return Contains.length > 0;
	};

	getUserDetails = (values, type) => {
		const { sameAddress } = this.state;
		const {
			address_1,
			address_2,
			city,
			country,
			email,
			firstName,
			lastName,
			zipcode,
			mobile,
			zone,
			billing_address_1,
			billing_address_2,
			billing_city,
			billing_country,
			billing_zipcode,
			billing_zone,
		} = values;

		if (type === 'shipping' || sameAddress) {
			return {
				address_1: address_1,
				address_2: address_2 || '',
				city: city,
				country: country,
				country_id: this.findValue(COUNTRY, country),
				email: email,
				firstname: firstName,
				lastname: lastName,
				postcode: zipcode,
				telephone: mobile,
				zone: zone,
				zone_id: this.findValue(ZONE, zone),
			};
		} else {
			return {
				address_1: billing_address_1,
				address_2: billing_address_2 || '',
				city: billing_city,
				country: billing_country,
				country_id: this.findValue(COUNTRY, billing_country),
				email: email,
				firstname: firstName,
				lastname: lastName,
				postcode: billing_zipcode,
				telephone: mobile,
				zone: billing_zone,
				zone_id: this.findValue(ZONE, billing_zone),
			};
		}
	};

	handleSubmitSuccess = async (values) => {
		const { delivery, sameAddress } = this.state;
		const {
			store: {
				isLoggedIn,
				createGuest,
				createGuestShipping,
				setPaymentAddress,
				setShippingAddress,
				getShippingMethods,
				getPaymentMethods,
			},
		} = this.props;

		const shippingBody = this.getUserDetails(values, 'shipping');
		const billingBody = this.getUserDetails(values, 'billing');

		// Check if the user is logged in
		if (isLoggedIn) {
			// IF user
			if (sameAddress) {
				// Set billing / shipping with the same address
				const paymentAddress = await setPaymentAddress(shippingBody);
				if (paymentAddress === 'error') return;
				const shippingAddress = await setShippingAddress(shippingBody);
				if (shippingAddress === 'error') return;
			} else {
				// Set billing / shipping with each addresses
				const paymentAddress = await setPaymentAddress(billingBody);
				if (paymentAddress === 'error') return;
				const shippingAddress = await setShippingAddress(shippingBody);
				if (shippingAddress === 'error') return;
			}

			// Get and Set shipping methods
			const getShippingMethodsAnswer = await getShippingMethods(delivery);
			if (getShippingMethodsAnswer === 'error') return;
		} else {
			// if guest
			// Create guest
			const createGuestAnswer = await createGuest(shippingBody);
			if (createGuestAnswer === 'error') return;

			// Create guest shipping
			const createGuestShippingAnswer = await createGuestShipping(shippingBody);
			if (createGuestShippingAnswer === 'error') return;

			// Get and Set shipping methods
			const getShippingMethodsAnswer = await getShippingMethods(delivery);
			if (getShippingMethodsAnswer === 'error') return;
		}

		const paymentAnswer = await getPaymentMethods();
		if (paymentAnswer === 'error') return;

		// Set view for payment
		this.switchPaymentView();
	};

	setStripeToken = (token) => {
		this.setState({ stripeToken: token, review: true });
	};

	placeOrder = async () => {
		const { stripeToken } = this.state;
		const {
			stripePayment,
			cart: { products },
		} = this.props.store;
		const paymentAnswer = await stripePayment(stripeToken);
		if (paymentAnswer === 'error') return;

		let HasIshields = products.filter((product) => product.name === 'iShields');

		if (HasIshields.length > 0) {
			setTimeout(function () {
				window.location.href = 'https://dentair.co.uk/';
			}, 3000);
		}

		this.scrollTop();
		this.setState({
			confirm: true,
			pickDelivery: false,
			review: false,
		});
	};

	render() {
		const {
			step,
			activeLeftView,
			activeRightView,
			pickDelivery,
			delivery,
			review,
			stripeToken,
			sameAddress,
			confirm,
		} = this.state;
		const {
			store,
			store: { recurringTotal, cart },
		} = this.props;

		return (
			<CheckoutWrapperCss>
				<SectionWrapper>
					<CheckoutInnerCss>
						<StepIndicatorCss active={!review && !confirm}>
							<StepCss active={step >= 0} onClick={() => this.changeStep(0)}>
								<StepNameCss>Delivery</StepNameCss>
							</StepCss>
							<LineCss />
							<StepCss active={step >= 1} onClick={() => this.changeStep(1)}>
								<StepNameCss>Payment</StepNameCss>
							</StepCss>
						</StepIndicatorCss>

						<ChooseDelivery
							active={pickDelivery}
							delivery={delivery}
							store={store}
							onSubmit={this.changeDelivery}
						/>
						<ReviewOrder
							leftView={activeLeftView}
							delivery={delivery}
							active={review}
							store={store}
							stripeToken={stripeToken}
							placeOrder={this.placeOrder}
							switchShippingView={this.switchShippingView}
							switchPaymentView={this.switchPaymentView}
							setCoupon={this.setCoupon}
						/>
						<ConfirmView active={confirm} store={store} delivery={delivery} />
						<ContentCss active={!(pickDelivery || review || confirm)}>
							<LeftSideCss>
								<AddressForm
									active={activeLeftView === 'shipping'}
									changeSameAddress={this.changeSameAddress}
									onSubmit={this.onSubmit}
									onSubmitExisting={this.onSubmitExisting}
									sameAddress={sameAddress}
									store={store}
									switchLoginView={this.switchLoginView}
								/>
								<AccountForm
									active={activeLeftView === 'login'}
									switchShippingView={this.switchShippingView}
									store={store}
								/>
								<PaymentForm
									active={activeLeftView === 'payment'}
									setStripeToken={this.setStripeToken}
									store={store}
								/>
							</LeftSideCss>
							<RightSideCss>
								<CartPreview
									active={activeRightView === 'cartPreview'}
									store={store}
									leftView={activeLeftView}
								/>
								<GuestCheckout
									active={activeRightView === 'login'}
									switchShippingView={this.switchShippingView}
									isRecurring={recurringTotal || recurringTotal !== 0}
								/>
							</RightSideCss>
						</ContentCss>
					</CheckoutInnerCss>
				</SectionWrapper>
			</CheckoutWrapperCss>
		);
	}
}

export default withContext(Checkout);
