import validatorjs from 'validatorjs';

const plugins = { dvr: validatorjs };

const fields = [
  {
    name: 'email',
    placeholder: 'Email address',
    rules: 'required|email|string',
  },
  {
    name: 'mobile',
    placeholder: 'Phone number (for delivery updates)',
    rules: 'required|string|numeric|min:9',
  },
  {
    name: 'firstName',
    placeholder: 'First Name',
    rules: 'required|string',
  },
  {
    name: 'lastName',
    placeholder: 'Last Name',
    rules: 'required|string',
  },
  {
    name: 'country',
    label: 'country',
    placeholder: 'Country/region',
    rules: 'required|string',
  },
  {
    name: 'zone',
    placeholder: 'County/state',
    rules: 'string',
  },
  {
    name: 'address_1',
    placeholder: 'street address',
    rules: 'required|string',
  },
  {
    name: 'address_2',
    placeholder: 'street address 2',
    rules: 'string',
  },
  {
    name: 'city',
    placeholder: 'City',
    rules: 'required|string',
  },
  {
    name: 'zipcode',
    placeholder: 'zip code',
    rules: 'required|string',
  },
  {
    name: 'billing_country',
    placeholder: 'Country/region',
    rules: '',
  },
  {
    name: 'billing_zone',
    placeholder: 'County/state',
    rules: '',
  },
  {
    name: 'billing_address_1',
    placeholder: 'street address',
    rules: '',
  },
  {
    name: 'billing_address_2',
    placeholder: 'street address 2',
    rules: '',
  },
  {
    name: 'billing_city',
    placeholder: 'city',
    rules: '',
  },
  {
    name: 'billing_zipcode',
    placeholder: 'zip code',
    rules: '',
  },
];

export { plugins, fields };
