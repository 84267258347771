import React, { Component } from "react";
import StripePayment from "./StripePayment";
import { StripeProvider, Elements } from "react-stripe-elements";
import { PaymentFormWrapperCss } from "./index.css";

class PaymentForm extends Component {
  render() {
    const {
      active,
      setStripeToken,
      store,
      store: { orderConfirm, loadingButton },
    } = this.props;
    if (typeof window === "undefined") return null;
    return (
      <PaymentFormWrapperCss active={active}>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
          <Elements>
            <StripePayment
              store={store}
              stripePayment={setStripeToken}
              orderConfirm={orderConfirm}
              loadingButton={loadingButton}
            />
          </Elements>
        </StripeProvider>
      </PaymentFormWrapperCss>
    );
  }
}

export default PaymentForm;
