import validatorjs from 'validatorjs';

const plugins = { dvr: validatorjs };

const fields = [
  {
    name: 'email',
    placeholder: 'E-mail',
    rules: 'required|email|string',
  },
  {
    name: 'password',
    placeholder: 'Password',
    rules: 'required',
  },
];

export { plugins, fields };
