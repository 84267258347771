export const colors = {
  primaryBlue: '#3A97C9',
  primaryBlack: '#111111',
  primaryWhite: '#EAEAEA',
  maxWhite: '#FFFFFF',

  secondaryBlue: '#4ABAF7',
  secondaryViolet: '#A36DE9',
  secondaryBlack1: '#1B1B1B',
  secondaryBlack2: '#232323',
  secondaryGray1: '#515151',
  secondaryGray2: '#6B6B6B',
  secondaryGray3: '#A1A1A1',
  secondaryGray4: '#A2A2A2',
  secondaryGray5: '#4F4F4F',
  secondaryGray6: '#c4c4c4',
  secondaryGray7: '#3E3E3E',
  secondaryWhite: '#EBEBEB',
  secondaryWhite2: '#F4F4F4',
  secondaryRed: '#EB5757',
  secondartyGreen: '#6FCF97',
};

export const screens = {
  xs: '478px',
  sm: '575px',
  md: '767px',
  lg: '991px',
  xl: '1200px',
  xxl: '1400px',
};

export const fonts = {
  primary: 'Yantramanav, sans-serif',
};

export const transition = 'cubic-bezier(0.64, 0.04, 0.35, 1)';
export const globalPadding = '100px 20px';
export const globalPaddingMobile = '60px 20px';
