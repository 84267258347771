import styled from 'styled-components';
import { colors, screens, transition } from '../../../../../constants/theme';

export const CartProductWrapperCss = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ImageWrapperCss = styled.div`
  max-width: 100px;
  height: 120px;
  border: 1px solid ${colors.secondaryGray3};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ProductInfoCss = styled.div`
  flex: 1;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NameCss = styled.h6`
  color: ${colors.secondaryGray3};
  margin: 0;
`;

export const BottomInfoCss = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const QtyCss = styled.h6`
  color: ${colors.secondaryGray3};
  margin-bottom: 5px;
  span {
    color: ${colors.secondaryGray2};
    margin-left: 15px;
    margin-bottom: 5px;
  }
`;
export const PriceCss = styled.h6`
  color: ${colors.secondaryGray2};
  margin-bottom: 5px;
`;

export const NameWrapperCss = styled.div``;
export const OptionsCss = styled.div``;
export const OptionCss = styled.h5`
  color: ${colors.secondaryGray3};
  margin: 0;
`;
