import React from 'react';
import PropTypes from 'prop-types';
import Bubble from '../Bubble';

import {
  DIRECTIONS__INFO_TOOLTIP_VERTICAL_LINE,
  TEXT_POSITION__INFO_TOOLTIP_VERTICAL_LINE,
  InfoTooltipBubble,
  InfoTooltipBorders,
  InfoTooltipContent,
  InfoTooltip,
} from './index.css';

function InfoTooltipVerticalLine({
  top,
  left,
  length,
  direction,
  textPosition,
  isShowContetnt,
  children,
  onMouseEnter,
  onMouseLeave,
  noAnimation,
}) {
  return (
    <InfoTooltip top={top} left={left}>
      <InfoTooltipBubble>
        <Bubble
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          noAnimation={noAnimation}
        />
      </InfoTooltipBubble>
      <InfoTooltipBorders
        length={length}
        direction={direction}
        textPosition={textPosition}
        isShowContetnt={isShowContetnt}
      >
        <InfoTooltipContent
          direction={direction}
          isShowContetnt={isShowContetnt}
        >
          {children}
        </InfoTooltipContent>
      </InfoTooltipBorders>
    </InfoTooltip>
  );
}

InfoTooltipVerticalLine.propTypes = {
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  direction: PropTypes.oneOf([
    DIRECTIONS__INFO_TOOLTIP_VERTICAL_LINE.TOP,
    DIRECTIONS__INFO_TOOLTIP_VERTICAL_LINE.BOTTOM,
  ]).isRequired,
  textPosition: PropTypes.oneOf([
    TEXT_POSITION__INFO_TOOLTIP_VERTICAL_LINE.RIGHT,
    DIRECTIONS__INFO_TOOLTIP_VERTICAL_LINE.LEFT,
  ]).isRequired,
  isShowContetnt: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};
InfoTooltipVerticalLine.defaultProps = {};

export default InfoTooltipVerticalLine;
