import styled from "styled-components";
import { colors, screens, transition } from "../../../../constants/theme";

export const CartWrapperCss = styled.div`
  left: 0;
  margin-top: 50px;
  opacity: ${(props) => (props.active ? 1 : 0)};
  pointer-events: ${(props) => (props.active ? "default" : "none")};
  position: ${(props) => (props.active ? "relative" : "absolute")};
  top: 0;
  transition: all 0.5s ${transition};
  width: 100%;

  @media (max-width: ${screens.lg}) {
    display: none;
  }
`;

export const TopRowCss = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const ItemCountCss = styled.p`
  color: ${colors.secondaryGray2};
  margin: 0;
`;
export const EditButtonCss = styled.h6`
  color: ${colors.secondaryBlue};
  margin: 0;
`;

export const ProductsWrapperCss = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.secondaryGray3};
  border-bottom: 1px solid ${colors.secondaryGray3};
  padding: 15px 0;
  margin-bottom: 15px;

  max-height: 340px;
  overflow: auto;
`;

export const NoMessageCss = styled.h4`
  color: ${colors.secondaryGray1};
  margin: 0;
`;

export const TotalWrapperCss = styled.div`
  margin-bottom: 10px;
`;

export const TotalTextCss = styled.h6`
  color: ${colors.secondaryGray1};
  margin-bottom: 10px;
  span {
    color: ${colors.primaryBlue};
  }
`;

export const SubtotalTextCss = styled.h6`
  color: ${colors.secondaryGray3};
  margin-bottom: 10px;

  span {
    color: ${colors.primaryBlue};
  }
`;

export const LineCss = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const GroupCss = styled.div`
  display: flex;
  align-items: center;
  h6 {
    margin: 0 15px 0 0;
  }
`;

export const InfoBubbleTextCss = styled.h6`
  color: ${colors.primaryWhite};
  &:last-child {
    margin-bottom: 0;
  }
`;
export const IShieldsDelivery = styled.h5`
  color: ${colors.secondaryGray3};
  padding: 20px 20px 0;
`;
export const IShieldsDeliveryWrapper = styled.div`
  width: 100%;
  background: ${colors.secondaryWhite2};
  margin: 20px 0;
`;
