import styled from 'styled-components';
import { colors, screens, transition } from '../../../../constants/theme';

export const GuestCheckoutWrapperCss = styled.div`
  left: 0;
  margin-top: 50px;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'default' : 'none')};
  position: ${props => (props.active ? 'relative' : 'absolute')};
  top: 0;
  transition: all 0.5s ${transition};
  width: 100%;
  background: ${colors.secondaryWhite2};
  padding: 20px;
`;

export const TitleCss = styled.h3`
  color: ${colors.secondaryGray1};
  margin-bottom: 20px;
  text-align: center;
`;

export const MessageCss = styled.h5`
  color: ${colors.secondaryGray3};
  margin-bottom: 20px;

  @media (max-width: ${screens.lg}) {
    text-align: center;
  }
`;

export const ButtonWrapperCss = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WarningCss = styled.h5`
  color: ${colors.secondaryRed};
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: ${screens.lg}) {
    text-align: center;
  }
`;
