import React, { Component } from 'react';
import MobxReactForm from 'mobx-react-form';

import {
	AddressFormWrapperCss,
	TitleCss,
	SubTitleSmallCss,
	RegisteredButtonCss,
	ShippingFormWrapperCss,
	FormTitleCss,
	Address2ButtonCss,
	SubTitleCss,
	ButtonWrapperCss,
	ErrorMessageCss,
	GreetingCss,
	ManualCheckoutCss,
	PreviousCheckoutCss,
	RowCss,
	PrevTitleCss,
	ChangeButtonCss,
	AddressListCss,
	CircleCss,
	InfoTextCss,
	InfoBoxCss,
} from './index.css';
import { fields, plugins } from './formSetup';
import InputText from '../InputText';
import InputSuggest from '../InputSuggest';
import Checkbox from '../Checkbox';
import { Button } from '../../../Shared';

const COUNTRY = 'country';
const ZONE = 'zone';

// Mobx setup
const form = new MobxReactForm({ fields }, { plugins });

class AddressForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			address2: false,
			zones: null,
			billingZones: null,
			view: 'manual',
			selectedAddrIndex: 0,
			selectedAddress: null,
			selectedBillingAddrIndex: 0,
			selectedBillingAddress: null,
			existingAddresses: null,
			defaultShippingCountry: null,
			defaultShippingZone: null,
			defaultBillingCountry: null,
			defaultBillingZone: null,
			fieldsTouched: null,
		};
	}

	handleChangeField = (e) => {
		this.setState({ fieldsTouched: 'yes' });
	};

	findValue = (type, value) => {
		let found = null;

		if (type === COUNTRY) {
			const { countries } = this.props.store;
			if (!countries) return null;
			const item = countries.filter((country) => {
				return country.name.toLowerCase() === String(value).toLowerCase();
			});
			found = item.length > 0 && item[0].country_id;
		} else if (type === ZONE) {
			const { zones } = this.props.store;
			if (!zones) return null;
			const item = zones.filter(
				(zone) => zone.name.toLowerCase() === String(value).toLowerCase()
			);
			found = item.length > 0 && item[0].zone_id;
		}

		return found;
	};

	handleCountrySelect = (name, type) => {
		const { getZones } = this.props.store;
		const countryId = this.findValue(COUNTRY, name);

		if (countryId) {
			getZones(countryId, type);
		}
	};

	static getDerivedStateFromProps(props, state) {
		const {
			sessionId,
			countries,
			getCountries,
			zones,
			billingZones,
			existingAddresses,
		} = props.store;
		const newState = {};

		if (existingAddresses !== state.existingAddresses && existingAddresses) {
			// If the state of existingAddresses changes select existing address
			newState.existingAddresses = existingAddresses;
			newState.view = 'existing';
			newState.selectedAddrIndex = 0;
			newState.selectedAddress = existingAddresses[0];
			newState.selectedBillingAddrIndex = 0;
			newState.selectedBillingAddress = existingAddresses[0];
		}

		if (sessionId && !countries) {
			getCountries();
		}
		if (zones !== state.zones) {
			newState.zones = zones;
		}
		if (billingZones !== state.billingZones) {
			newState.billingZones = billingZones;
		}
		if (countries !== state.countries) {
			newState.countries = countries;
		}
		return newState;
	}

	onSubmit = () => {
		const { view, selectedAddress, selectedBillingAddress, fieldsTouched } = this.state;
		const { onSubmit, onSubmitExisting } = this.props;

		if (view === 'existing' || (view === 'manual' && fieldsTouched === 'no')) {
			onSubmitExisting(selectedAddress, selectedBillingAddress);
		} else {
			form.submit({
				onSuccess: (fieldset) => {
					const values = fieldset.values();
					onSubmit(values);
				},
				onError: (fieldset) => {
					// TODO Scroll to the first field with error !
					console.log('wof', fieldset.errors());
				},
			});
		}
	};

	preselectAddress = async () => {
		const { selectedAddress, selectedBillingAddress } = this.state;
		const {
			sameAddress,
			store: { account, getZones },
		} = this.props;
		if (!selectedAddress) return;

		const {
			country,
			zone,
			address_1,
			address_2,
			firstname,
			lastname,
			city,
			postcode,
		} = selectedAddress;
		const {
			address_1: billing_address_1,
			address_2: billing_address_2,
			country: billing_country,
			zone: billing_zone,
			city: billing_city,
			postcode: billing_postcode,
		} = selectedBillingAddress;

		form.$('country').set('value', country.toLowerCase());

		this.setState({ defaultShippingCountry: '' }, () => {
			this.setState({ defaultShippingCountry: country.toLowerCase() });
		});

		const countryId = this.findValue(COUNTRY, country);
		if (countryId) {
			await getZones(countryId, 'shipping');

			form.$('zone').set('value', zone.toLowerCase());
			this.setState({ defaultShippingZone: '' }, () => {
				this.setState({ defaultShippingZone: zone.toLowerCase() });
			});
		}

		form.$('firstName').set(firstname);
		form.$('lastName').set(lastname);
		form.$('zipcode').set(postcode);
		form.$('city').set(city);
		form.$('address_1').set(address_1);
		form.$('address_2').set(address_2);
		form.$('email').set(account.email);
		form.$('mobile').set(account.telephone);
		form.validate();
		if (!sameAddress) {
			form.$('billing_country').set('value', country.toLowerCase());

			this.setState({ defaultBillingCountry: '' }, () => {
				this.setState({ defaultBillingCountry: billing_country.toLowerCase() });
			});

			const billingCountryId = this.findValue(COUNTRY, billing_country);
			if (billingCountryId) {
				await getZones(billingCountryId, 'billing');

				form.$('billing_zone').set('value', billing_zone.toLowerCase());
				this.setState({ defaultBillingZone: '' }, () => {
					this.setState({ defaultBillingZone: billing_zone.toLowerCase() });
				});
			}

			form.$('billing_city').set(billing_city);
			form.$('billing_zipcode').set(billing_postcode);
			form.$('billing_address_1').set(billing_address_1);
			form.$('billing_address_2').set(billing_address_2);
		}
	};

	toggleBilling = (value) => {
		this.props.changeSameAddress(value.target.checked);
	};

	renderManualCheckout = () => {
		const {
			address2,
			zones,
			view,
			billingZones,
			countries,
			defaultShippingCountry,
			defaultShippingZone,
			defaultBillingCountry,
			defaultBillingZone,
		} = this.state;
		const {
			sameAddress,
			store: { isLoggedIn },
		} = this.props;
		return (
			<ManualCheckoutCss active={view === 'manual'}>
				<ShippingFormWrapperCss>
					{/* <FormTitleCss>Enter your name and address:</FormTitleCss> */}
					<InputText
						field={form.$('firstName')}
						handleOnChange={this.handleChangeField}
						req
					/>
					<InputText
						field={form.$('lastName')}
						handleOnChange={this.handleChangeField}
						req
					/>
					<InputText
						field={form.$('address_1')}
						handleOnChange={this.handleChangeField}
						req
					/>
					{address2 ? (
						<InputText
							field={form.$('address_2')}
							handleOnChange={this.handleChangeField}
							req
						/>
					) : (
						<Address2ButtonCss onClick={() => this.setState({ address2: true })}>
							street address 2
						</Address2ButtonCss>
					)}
					<InputText field={form.$('city')} handleOnChange={this.handleChangeField} req />
					<InputSuggest
						defaultValue={defaultShippingZone}
						field={form.$('zone')}
						id="zone"
						onChange={this.handleChangeField}
						placeholder="County/state"
						req
						values={zones}
					/>
					<InputSuggest
						defaultValue={defaultShippingCountry}
						field={form.$('country')}
						handleCountrySelect={(name) => this.handleCountrySelect(name, 'shipping')}
						id={COUNTRY}
						onChange={() => {
							this.handleChangeField();
							this.setState({ zones: null });
						}}
						placeholder="Country"
						req
						required
						values={countries}
					/>
					<InputText
						field={form.$('zipcode')}
						handleOnChange={this.handleChangeField}
						req
						small
					/>
					{isLoggedIn && (
						<Checkbox checked={sameAddress} onChange={this.toggleBilling}>
							Use this as billing address.
						</Checkbox>
					)}
				</ShippingFormWrapperCss>

				{!sameAddress && (
					<ShippingFormWrapperCss marginTop>
						<FormTitleCss>Enter your billing address:</FormTitleCss>

						<InputSuggest
							defaultValue={defaultBillingCountry}
							field={form.$('billing_country')}
							handleCountrySelect={(name) =>
								this.handleCountrySelect(name, 'billing')
							}
							id={COUNTRY}
							onChange={() => {
								this.handleChangeField();
								this.setState({ zones: null });
							}}
							placeholder="Country"
							req
							required
							values={countries}
						/>
						<InputText
							field={form.$('billing_zipcode')}
							handleOnChange={this.handleChangeField}
							req
							small
						/>
						<InputSuggest
							defaultValue={defaultBillingZone}
							field={form.$('billing_zone')}
							id="zone"
							onChange={this.handleChangeField}
							placeholder="County/state"
							req
							required
							values={billingZones}
						/>
						<InputText
							field={form.$('billing_city')}
							handleOnChange={this.handleChangeField}
							req
						/>
						<InputText
							field={form.$('billing_address_1')}
							handleOnChange={this.handleChangeField}
							req
						/>
						{address2 ? (
							<InputText
								field={form.$('billing_address_2')}
								handleOnChange={this.handleChangeField}
								req
							/>
						) : (
							<Address2ButtonCss onClick={() => this.setState({ address2: true })}>
								street address 2
							</Address2ButtonCss>
						)}
					</ShippingFormWrapperCss>
				)}

				<SubTitleCss>What’s your contact information?</SubTitleCss>
				<ShippingFormWrapperCss>
					<InputText
						field={form.$('email')}
						handleOnChange={this.handleChangeField}
						req
					/>
					<InputText
						field={form.$('mobile')}
						handleOnChange={this.handleChangeField}
						req
					/>
				</ShippingFormWrapperCss>
			</ManualCheckoutCss>
		);
	};

	selectAddress = (addrId) => {
		const { existingAddresses } = this.props.store;
		if (!existingAddresses) return;
		existingAddresses.map((addr, index) => {
			if (addr.address_id === addrId) {
				this.setState({
					selectedAddrIndex: index,
					selectedAddress: addr,
				});
			}
		});
	};

	selectBillingAddress = (addrId) => {
		const { existingAddresses } = this.props.store;
		if (!existingAddresses) return;
		existingAddresses.map((addr, index) => {
			if (addr.address_id === addrId) {
				this.setState({
					selectedBillingAddrIndex: index,
					selectedBillingAddress: addr,
				});
			}
		});
	};

	selectManual = () => {
		this.setState({ view: 'manual', fieldsTouched: 'no' });
		this.preselectAddress();
	};

	renderPreviousCheckout = () => {
		const {
			sameAddress,
			store: { existingAddresses, isLoggedIn },
		} = this.props;
		const { selectedAddrIndex, selectedBillingAddrIndex, view } = this.state;

		return (
			<PreviousCheckoutCss active={view === 'existing'}>
				<RowCss>
					<PrevTitleCss>Delivery details</PrevTitleCss>
					<ChangeButtonCss onClick={this.selectManual}>change</ChangeButtonCss>
				</RowCss>

				<AddressListCss>
					{existingAddresses &&
						existingAddresses.map((address, index) => {
							const {
								address_id,
								address_1,
								address_2,
								firstname,
								lastname,
								country,
								city,
								postcode,
							} = address;
							return (
								<InfoBoxCss
									key={index}
									active={selectedAddrIndex === index}
									onClick={() => this.selectAddress(address_id)}
								>
									<CircleCss active={selectedAddrIndex === index} />
									<InfoTextCss>
										{`${firstname} ${lastname}, ${country}, ${city}, ${address_1}${address_2}, ${postcode}`}
									</InfoTextCss>
								</InfoBoxCss>
							);
						})}
				</AddressListCss>
				<div style={{ padding: '20px' }}>
					{isLoggedIn && (
						<Checkbox checked={sameAddress} onChange={this.toggleBilling}>
							Use this as billing address.
						</Checkbox>
					)}
				</div>
				{!sameAddress && (
					<>
						<RowCss>
							<PrevTitleCss>Billing details</PrevTitleCss>
							<ChangeButtonCss onClick={this.selectManual}>change</ChangeButtonCss>
						</RowCss>
						<AddressListCss>
							{existingAddresses &&
								existingAddresses.map((address, index) => {
									const {
										address_id,
										address_1,
										address_2,
										firstname,
										lastname,
										country,
										city,
										postcode,
									} = address;
									return (
										<InfoBoxCss
											key={index}
											active={selectedBillingAddrIndex === index}
											onClick={() => this.selectBillingAddress(address_id)}
										>
											<CircleCss
												active={selectedBillingAddrIndex === index}
											/>
											<InfoTextCss>
												{`${firstname} ${lastname}, ${country}, ${city}, ${address_1}${address_2}, ${postcode}`}
											</InfoTextCss>
										</InfoBoxCss>
									);
								})}
						</AddressListCss>
					</>
				)}
			</PreviousCheckoutCss>
		);
	};

	render() {
		const {
			active,
			store: { loadingButton, checkoutError },
		} = this.props;

		return (
			<AddressFormWrapperCss active={active}>
				<TitleCss>Shipping information</TitleCss>
				<SubTitleSmallCss>Expected delivery time: 10-14 days</SubTitleSmallCss>

				{this.renderManualCheckout()}
				{this.renderPreviousCheckout()}
				{checkoutError && <ErrorMessageCss>{checkoutError}</ErrorMessageCss>}
				<ButtonWrapperCss>
					<Button type="primary" revert onClick={this.onSubmit} loading={loadingButton}>
						continue to Payment
					</Button>
				</ButtonWrapperCss>
			</AddressFormWrapperCss>
		);
	}
}

export default AddressForm;
