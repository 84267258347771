import React, { Component } from "react";

import { colors, fonts } from "../../../../../constants/theme";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Button } from "../../../../Shared";
import { ReactComponent as PadLockSvg } from "./padlock.svg";
import {
  StripePaymentWrapperCss,
  TitleWrapperCss,
  TitleCss,
  CardWrapperCss,
  CartTitleCss,
  ButtonWrapperCss,
  MessageCss,
  ReturnButtonCss,
  ErrorMessageCss,
} from "./index.css";

class StripePayment extends Component {
  state = {
    error: null,
    loading: false,
  };
  handleSubmit = async () => {
    const { stripePayment, orderConfirm, stripe } = this.props;

    this.setState({ loading: true, error: null });

    const token = await stripe.createSource({
      type: "card",
      metadata: {
        order_id: orderConfirm.order_id,
        is_recurring: "0",
        payment_button: "0",
        store_id: orderConfirm.store_id,
      },
      owner: {
        email: orderConfirm.email,
        name: `${orderConfirm.firstname} ${orderConfirm.lastname}`,
        phone: orderConfirm.telephone,
        verified_address: null,
        verified_email: null,
        verified_name: null,
        verified_phone: null,
      },
    });
    if (token && token.error) {
      this.setState({
        error: token.error.message,
        loading: false,
      });
    } else {
      stripePayment(token);
      this.setState({ loading: false, error: null });
    }
  };

  render() {
    const { error, loading } = this.state;
    return (
      <StripePaymentWrapperCss>
        <TitleWrapperCss>
          <PadLockSvg />
          <TitleCss>Secure checkout</TitleCss>
        </TitleWrapperCss>
        <CardWrapperCss>
          {/* <CartTitleCss>Card number</CartTitleCss> */}

          <CardElement
            style={{
              base: {
                fontSize: "14px",
                fontFamily: fonts.primary,

                letterSpacing: "0.1em",
                textTransform: "uppercase",
                color: colors.secondaryGray2,
              },
            }}
            hidePostalCode
          />
          {error && <ErrorMessageCss>{error}</ErrorMessageCss>}
        </CardWrapperCss>
        <ButtonWrapperCss>
          <Button
            type="primary"
            loading={loading}
            revert
            onClick={this.handleSubmit}
          >
            Review your order
          </Button>
        </ButtonWrapperCss>
        {/*<MessageCss>*/}
        {/*  Free returns within the 45 day trial period. No need to find a*/}
        {/*  printer, no need to pay for postage.*/}
        {/*</MessageCss>*/}
        {/*<a href="/returns" target="_blank">*/}
        {/*  <ReturnButtonCss>returns process</ReturnButtonCss>*/}
        {/*</a>*/}
      </StripePaymentWrapperCss>
    );
  }
}

export default injectStripe(StripePayment);
