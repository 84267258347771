import styled from "styled-components";

export const FormButtonCss = styled.div`
  .loader {
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    display: inline-block;
    display: none;
    margin-top: -5px;
  }
`;
