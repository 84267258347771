import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TabWrapperCss,
  TitleWrapperCss,
  TitleCss,
  ContentWrapperCss,
  ContentCss,
} from './index.css';

class TabChange extends Component {
  constructor(props) {
    super(props);

    this.tabsRef = React.createRef();
  }

  componentDidUpdate(props) {
    const { titles, activeTab } = this.props;
    if (
      props.activeTab !== this.props.activeTab &&
      typeof window !== 'undefined' &&
      this.tabsRef &&
      this.tabsRef.current
    ) {
      const scrollWidth = this.tabsRef.current.scrollWidth;
      const scrollRange = scrollWidth - window.innerWidth;

      // THIS LOGIC DOES NOT WORK FOR ANYTHING ELSE THAN 3 TITLES

      this.tabsRef.current.scrollTo({
        top: 0,
        left: (scrollRange / (titles.length - 1)) * activeTab,
        behavior: 'smooth',
      });
    }
  }

  renderTitles = () => {
    const { titles, activeTab, selectTab } = this.props;

    return (
      <TitleWrapperCss ref={this.tabsRef}>
        {titles.map((title, index) => (
          <TitleCss
            key={index}
            active={index === activeTab}
            onClick={() => selectTab(index)}
          >
            {title}
          </TitleCss>
        ))}
      </TitleWrapperCss>
    );
  };

  renderContent = () => {
    const { contents, activeTab } = this.props;

    return (
      <ContentWrapperCss>
        {contents.map((content, index) => (
          <ContentCss key={index} active={index === activeTab}>
            {content}
          </ContentCss>
        ))}
      </ContentWrapperCss>
    );
  };

  render() {
    const { renderTitles, renderContent } = this;
    return (
      <TabWrapperCss>
        {renderTitles()}
        {renderContent()}
      </TabWrapperCss>
    );
  }
}

export default TabChange;

TabChange.propsTypes = {
  titles: PropTypes.array.isRequired,
  contents: PropTypes.array.isRequired,
};

TabChange.defaultProps = {
  titles: [],
  contents: [],
};
