import styled from 'styled-components';
import { colors, screens, transition } from '../../../constants/theme';

export const CheckoutWrapperCss = styled.div`
  background: ${colors.primaryWhite};
  min-height: 100vh;
  padding-top: 20px;
  @media (max-width: ${screens.lg}) {
    .container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
`;

export const CheckoutInnerCss = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width: 850px;
  position: relative;
  width: 100%;
`;

export const StepIndicatorCss = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 60px;
  max-width: 350px;
  left: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'default' : 'none')};
  position: ${props => (props.active ? 'relative' : 'absolute')};
  top: 0;
  transition: all 0.5s ${transition};
  width: 100%;

  @media (max-width: ${screens.sm}) {
    max-width: 250px;
  }
  @media (max-width: ${screens.xs}) {
    max-width: 200px;
  }
`;

export const StepCss = styled.div`
  background: ${props => (props.active ? colors.secondaryBlue : 'transparent')};
  border-radius: 50%;
  border: 1px solid
    ${props => (props.active ? colors.secondaryBlue : colors.secondaryGray3)};
  flex: auto;
  height: 11px;
  min-width: 11px;
  position: relative;
`;
export const StepNameCss = styled.h6`
  color: ${colors.secondaryGray3};
  left: 50%;
  margin: 15px 0 0 0;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
`;

export const LineCss = styled.div`
  background: ${colors.secondaryGray3};
  flex: auto;
  height: 1px;
  width: 100%;
`;

export const ContentCss = styled.div`
  display: flex;
  justify-content: space-between;
  left: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'default' : 'none')};
  position: ${props => (props.active ? 'relative' : 'absolute')};
  top: 0;
  transition: all 0.5s ${transition};
  width: 100%;

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
  }
`;

export const LeftSideCss = styled.div`
  width: 53%;
  position: relative;

  @media (max-width: ${screens.lg}) {
    width: 100%;
  }
`;

export const RightSideCss = styled.div`
  width: 40%;
  position: relative;
  @media (max-width: ${screens.lg}) {
    width: 100%;
  }
`;
