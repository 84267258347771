import styled from 'styled-components';
import { colors, transition, screens } from '../../../constants/theme';

export const AnimationWrapperCss = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (max-width: ${screens.lg}) {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const AnimationCss = styled.div`
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '200px'};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  max-width: ${props => props.maxWidth || '250px'};
  margin: 0 auto;

  svg {
    height: 100%;
  }
`;

export const AnimationTitle = styled.h6`
  color: ${colors.primaryWhite};
  margin: 40px 0 20px;
`;

export const AnimationDescription = styled.p`
  color: ${colors.secondaryGray3};
  max-width: 250px;
  padding: 0 15px;
  margin: 0 auto;
`;
