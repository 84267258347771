import styled from 'styled-components';
import { colors, screens, transition } from '../../../../constants/theme';

export const CheckboxWrapperCss = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const TextCss = styled.h5`
  color: ${colors.secondaryGray2};
  margin: 0 0 0 10px;
`;
