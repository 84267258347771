import React, { Component } from "react";
import MobxReactForm from "mobx-react-form";

import { fields, plugins } from "./signupFormSetup";
import {
  LoginFormWrapperCss,
  ButtonWrapperCss,
  TermsCss,
  LinkCss,
  ErrorCss,
} from "./index.css";
import { FormWrapperCss } from "../index.css";
import InputText from "../../InputText";
import { Button } from "../../../../Shared";

// Mobx setup
const form = new MobxReactForm({ fields }, { plugins });

class LoginForm extends Component {
  handleSignup = () => {
    const { register } = this.props.store;
    form.submit({
      onSuccess: (fieldset) => {
        const {
          firstName,
          lastName,
          email,
          password,
          confirmPassword,
          telephone,
        } = fieldset.values();

        register({
          firstName,
          lastName,
          email,
          password,
          confirmPassword,
          telephone,
        });
      },
      onError: (fieldset) => {
        // TODO Scroll to the first field with error !
        console.log("wof", fieldset.errors());
      },
    });
  };
  render() {
    const { registerError } = this.props.store;
    return (
      <LoginFormWrapperCss>
        <FormWrapperCss>
          <InputText field={form.$("firstName")} req />
          <InputText field={form.$("lastName")} req />
          <InputText field={form.$("email")} req />
          <InputText field={form.$("password")} type="password" req />
          <InputText field={form.$("confirmPassword")} type="password" req />
          <InputText field={form.$("telephone")} req />
        </FormWrapperCss>

        <TermsCss>By creating your account, you agree to our</TermsCss>

        <LinkCss>Terms and Conditions & Privacy Policy</LinkCss>

        {registerError && <ErrorCss>{registerError}</ErrorCss>}
        <ButtonWrapperCss>
          <Button type="primary" revert onClick={this.handleSignup}>
            create account
          </Button>
        </ButtonWrapperCss>
      </LoginFormWrapperCss>
    );
  }
}

export default LoginForm;
