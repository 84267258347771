import styled from 'styled-components';
import { colors, screens, transition } from '../../../../constants/theme';

export const AddressFormWrapperCss = styled.div`
	left: 0;
	opacity: ${(props) => (props.active ? 1 : 0)};
	pointer-events: ${(props) => (props.active ? 'default' : 'none')};
	position: ${(props) => (props.active ? 'relative' : 'absolute')};
	top: 0;
	transition: all 0.5s ${transition};
	width: 100%;
`;

export const TitleCss = styled.h3`
	color: ${colors.secondaryBlack1};
	margin-bottom: 20px;
	text-align: center;
`;

export const RegisteredButtonCss = styled.h6`
	color: ${colors.secondaryBlue};
	cursor: pointer;
	margin-bottom: 25px;
	text-align: center;
`;

export const ShippingFormWrapperCss = styled.div`
	background: ${colors.secondaryWhite2};
	margin-top: ${(props) => (props.marginTop ? '20px' : 0)};
	padding: 20px;
	position: relative;
	z-index: 5;
`;

export const FormTitleCss = styled.p`
	color: ${colors.secondaryGray1};
`;

export const Address2ButtonCss = styled.h6`
	color: ${colors.secondaryBlue};
	cursor: pointer;
	padding-left: 30px;
`;

export const SubTitleCss = styled.p`
	margin: 0;
	color: ${colors.secondaryGray1};
	padding: 20px;
`;

export const ButtonWrapperCss = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px;

	@media (max-width: ${screens.lg}) {
		flex-direction: column;
		button {
			margin-bottom: 15px;
		}
	}
`;

export const ErrorMessageCss = styled.h5`
	color: ${colors.secondaryRed};
	margin: 0;
	padding: 20px;
`;

export const GreetingCss = styled.h6`
	color: ${colors.secondaryGray1};
	text-align: center;

	span {
		color: ${colors.secondaryBlue};
	}
`;

export const ManualCheckoutCss = styled.div`
	left: 0;
	opacity: ${(props) => (props.active ? 1 : 0)};
	pointer-events: ${(props) => (props.active ? 'default' : 'none')};
	position: ${(props) => (props.active ? 'relative' : 'absolute')};
	top: 0;
	transition: all 0.5s ${transition};
	width: 100%;
`;
export const PreviousCheckoutCss = styled.div`
	left: 0;
	opacity: ${(props) => (props.active ? 1 : 0)};
	pointer-events: ${(props) => (props.active ? 'default' : 'none')};
	position: ${(props) => (props.active ? 'relative' : 'absolute')};
	top: 0;
	transition: all 0.5s ${transition};
	width: 100%;
`;

export const RowCss = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	margin-bottom: 15px;
`;

export const PrevTitleCss = styled.p`
	color: ${colors.secondaryGray1};
	margin: 0;
`;

export const ChangeButtonCss = styled.h6`
	color: ${colors.secondaryBlue};
	margin: 0;
	cursor: pointer;
`;

export const InfoBoxCss = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 25px;
	min-height: 60px;
	background: ${(props) => (props.active ? colors.secondaryWhite2 : 'transparent')};
	cursor: pointer;
`;

export const CircleCss = styled.div`
	position: relative;
	width: 20px;
	margin-right: 30px;
	height: 20px;
	border-radius: 50%;
	border: 1px solid ${colors.secondaryBlue};
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		width: 11px;
		height: 11px;
		border-radius: 50%;
		background: ${(props) => (props.active ? colors.secondaryBlue : 'transparent')};
	}
`;

export const InfoTextCss = styled.h5`
	color: ${colors.secondaryBlack1};
	margin: 0;
	flex: 2;
`;

export const AddressListCss = styled.div`
	max-height: 200px;
	overflow: auto;
	margin-bottom: 20px;
`;

export const SubTitleSmallCss = styled.h5`
	color: ${colors.secondaryGray3};
	text-align: center;
	margin: 0;
	margin-bottom: 10px;
`;
