import React from 'react';
import PropTypes from 'prop-types';
import Bubble from '../Bubble';

import {
  InfoTooltip,
  InfoTooltipBubble,
  InfoTooltipBorders,
  InfoTooltipContent,
  DIRECTIONS__INFO_TOOLTIP_HORISONTAL_LINE,
  TEXT_POSITION__INFO_TOOLTIP_HORISONTAL_LINE,
} from './index.css';

function InfoTooltipHorisontalLine({
  top,
  left,
  length,
  direction,
  textPosition,
  isShowContetnt,
  children,
  onMouseEnter,
  onMouseLeave,
  delay,
  noAnimation,
}) {
  return (
    <InfoTooltip top={top} left={left}>
      <InfoTooltipBubble>
        <Bubble
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          noAnimation={noAnimation}
        />
      </InfoTooltipBubble>
      <InfoTooltipBorders
        length={length}
        direction={direction}
        textPosition={textPosition}
        isShowContetnt={isShowContetnt}
        delay={delay}
      >
        <InfoTooltipContent isShowContetnt={isShowContetnt} delay={delay}>
          {children}
        </InfoTooltipContent>
      </InfoTooltipBorders>
    </InfoTooltip>
  );
}

InfoTooltipHorisontalLine.propTypes = {
  delay: PropTypes.number,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  direction: PropTypes.oneOf([
    DIRECTIONS__INFO_TOOLTIP_HORISONTAL_LINE.LEFT,
    DIRECTIONS__INFO_TOOLTIP_HORISONTAL_LINE.RIGHT,
  ]).isRequired,
  textPosition: PropTypes.oneOf([
    TEXT_POSITION__INFO_TOOLTIP_HORISONTAL_LINE.TOP,
    DIRECTIONS__INFO_TOOLTIP_HORISONTAL_LINE.BOTTOM,
  ]).isRequired,
  isShowContetnt: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};
InfoTooltipHorisontalLine.defaultProps = {
  delay: 0,
};

export default InfoTooltipHorisontalLine;
