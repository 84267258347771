import React, { Component } from "react";

import {
  AnimationWrapperCss,
  AnimationCss,
  AnimationTitle,
  AnimationDescription,
} from "./index.css";

class Animation extends Component {
  state = {
    showAnimations: false,
  };

  renderAnimations = ({ isIntersecting }) => {
    if (isIntersecting && this.props.delay) {
      setTimeout(() => {
        this.setState({ showAnimations: isIntersecting });
      }, this.props.delay);
    } else {
      this.setState({ showAnimations: isIntersecting });
    }
  };

  render() {
    const {
      animation,
      title,
      description,
      delay,
      width,
      height,
      maxWidth,
    } = this.props;
    const { showAnimations } = this.state;
    return (
      <AnimationWrapperCss>
        <AnimationCss width={width} height={height} maxWidth={maxWidth}>
          {showAnimations && animation}
        </AnimationCss>
        {title && <AnimationTitle>{title}</AnimationTitle>}
        {description && (
          <AnimationDescription>{description}</AnimationDescription>
        )}
      </AnimationWrapperCss>
    );
  }
}

export default Animation;
