import styled from 'styled-components';
import { colors, screens, transition } from '../../../../constants/theme';

export const ChooseDeliveryWrapperCss = styled.div`
  height: ${props => (props.active ? '100%' : 0)};
  left: 0;
  margin: 0 auto;
  max-width: 610px;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'default' : 'none')};
  position: ${props => (props.active ? 'relative' : 'absolute')};
  top: 0;
  transition: all 0.5s ${transition};
  width: 100%;
`;

export const TitleCss = styled.h3`
  color: ${colors.secondaryBlack1};
  text-align: center;
  margin: 40px 0 30px;

  @media (max-width: ${screens.lg}) {
    padding: 0 20px;
  }
`;

export const OptionsWrapperCss = styled.div`
  border-bottom: 1px solid ${colors.secondaryGray2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

export const OptionCss = styled.h4`
  flex: 1;
  text-align: center;
  margin: 0;
  padding: 25px 0;
  cursor: pointer;
  transition: color 0.25s ${transition};
  color: ${props =>
    props.active ? colors.primaryBlack : colors.secondaryGray3};

  @media (max-width: ${screens.md}) {
    span {
      display: none;
    }
  }
`;

export const TextMessageCss = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;

  h6 {
    color: ${colors.secondaryBlue};
    margin: 0;
    padding-left: 6px;
  }
`;

export const DeliversToCss = styled.p`
  color: ${colors.secondaryGray1};
  margin: 0;
  text-align: center;
`;

export const InfoBoxCss = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  min-height: 60px;
  border: 1px solid ${colors.secondaryBlue};
  background: ${colors.secondaryWhite2};

  @media (max-width: ${screens.lg}) {
    padding: 10px 15px;
  }
`;

export const CircleCss = styled.div`
  position: relative;
  width: 20px;
  margin-right: 30px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${colors.secondaryBlue};
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: ${colors.secondaryBlue};
  }

  @media (max-width: ${screens.lg}) {
    margin-right: 15px;
  }
`;
export const InfoTextCss = styled.h5`
  color: ${colors.secondaryBlack1};
  margin: 0;
  flex: 2;
`;
export const RightInfoTextCss = styled.h5`
  color: ${colors.secondaryBlack1};
  margin: 0;
  flex: 1;
`;

export const FreeCss = styled.h6`
  color: ${colors.secondaryBlack1};
  width: 10%;
  margin: 0;
  @media (max-width: ${screens.lg}) {
    min-width: 45px;
    margin-left: 15px;
  }
`;

export const ButtonWrapperCss = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

export const ErrorMessageCss = styled.h5`
  color: ${colors.secondaryRed};
  margin: 0;
  padding: 20px;
`;
