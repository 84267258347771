import React from 'react';
import Autosuggest from 'react-autosuggest';
import { observer } from 'mobx-react';
import { ErrorCss, InputWrapperCss } from './index.css';

class InputSuggest extends React.Component {
  constructor() {
    super();

    this.state = {
      values: null,
      value: null,
      suggestions: [],
      required: null,
      defaultValue: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};
    if (props.defaultValue !== state.defaultValue) {
      newState.value = props.defaultValue;
      newState.defaultValue = props.defaultValue;
    }

    if (props.values !== state.values && props.values) {
      const newValues = [];
      const lowercaseCountries = props.values.map(item => {
        newValues.push({
          ...item,
          name: item.name.toLowerCase(),
        });
        return item.name.toLowerCase();
      });

      props.field.set(
        'rules',
        `${props.required ? 'required|' : ''}string|in:${lowercaseCountries}`
      );

      newState.values = newValues;
      newState.value = null;
    }

    return newState;
  }

  onChange = (e, { newValue }) => {
    const { id, handleCountrySelect, field } = this.props;
    if (id === 'country') {
      handleCountrySelect(newValue.toLowerCase());
    }

    if (this.props.onChange) {
      this.props.onChange(e);
    }

    field.set('value', newValue.toLowerCase());
    field.validate();
    this.setState({
      value: newValue.toLowerCase(),
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    const suggestions = this.getSuggestions(value);

    this.setState({
      suggestions,
    });
  };

  findValue = value => {
    const { values } = this.state;

    return values.filter(
      country => country.name.toLowerCase() === value.toLowerCase()
    );
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const { values } = this.state;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 || !values || values === undefined
      ? []
      : values.filter(
          country =>
            country.name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = suggestion => suggestion.name.toLowerCase();

  // Use your imagination to render suggestions.
  renderSuggestion = suggestion => <div>{suggestion.name.toLowerCase()}</div>;

  render() {
    const { value, suggestions, defaultValue, values } = this.state;
    const { placeholder, field, id } = this.props;
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      ...field.bind({
        placeholder,
        onChange: this.onChange,
        // value: value || value === '' ? value : defaultValue,
        disabled: !values && !defaultValue,
        autoComplete: 'off',
      }),
    };
    return (
      <InputWrapperCss
        error={!!field.error}
        success={field.value && !field.error}
      >
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          highlightFirstSuggestion
          id={id}
        />
        <ErrorCss active={!!field.error}>{field.error || 'NOTHING'}</ErrorCss>
      </InputWrapperCss>
    );
  }
}

export default observer(InputSuggest);
