import styled from 'styled-components';
import { colors, transition, screens } from '../../../constants/theme';

export const TabWrapperCss = styled.div``;
export const TitleWrapperCss = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100vw;
  overflow: auto;
  position: relative;
  z-index: 15;

  @media (max-width: 500px) {
    justify-content: flex-start;
  }
`;

export const TitleCss = styled.h4`
  cursor: pointer;
  transition: color 0.5s ${transition};
  padding: 0 100px;
  color: ${props =>
    props.active ? colors.primaryWhite : colors.secondaryGray2};

  @media (max-width: ${screens.md}) {
    padding: 0 20px;
  }
`;

export const ContentWrapperCss = styled.div`
  position: relative;
  height: 75vh;
  max-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentCss = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  position: absolute;
  right: 0;
  top: 0;
  transition: all 1500ms ${transition};
  z-index: ${props => (props.active ? 5 : 4)};
`;
