import React from 'react';
import {
  GuestCheckoutWrapperCss,
  TitleCss,
  MessageCss,
  ButtonWrapperCss,
  WarningCss,
} from './index.css';
import { Button } from '../../../Shared';

const GuestCheckout = ({ active, switchShippingView, isRecurring }) => (
  <GuestCheckoutWrapperCss active={active}>
    <TitleCss>Guest Checkout</TitleCss>
    {isRecurring ? (
      <WarningCss>
        Guest checkout is not available for recurring products
      </WarningCss>
    ) : (
      <MessageCss>
        Checkout as a guest and you will have an opportunity to create an
        account in the end
      </MessageCss>
    )}
    <ButtonWrapperCss>
      <Button
        type="primary"
        revert
        onClick={switchShippingView}
        disabled={isRecurring}
      >
        continue as Guest
      </Button>
    </ButtonWrapperCss>
  </GuestCheckoutWrapperCss>
);

export default GuestCheckout;
