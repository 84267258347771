import styled from 'styled-components';
import { colors, screens, transition } from '../../../../constants/theme';

export const ConfirmViewWrapperCss = styled.div`
  min-height: ${props => (props.active ? '50vh' : 0)};

  display: flex;
  flex-direction: column;
  justify-content: center;

  left: 0;
  margin: 0 auto;
  max-width: 600px;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'default' : 'none')};
  position: ${props => (props.active ? 'relative' : 'absolute')};
  top: 0;
  transition: all 0.5s ${transition};
  width: 100%;
`;

export const TitleCss = styled.h3`
  color: ${colors.secondaryBlack1};
  text-align: center;
  margin-bottom: 30px;
  padding: 0 20px;
`;

export const TextWrapperCss = styled.div`
  margin-bottom: 20px;

  @media (max-width: ${screens.lg}) {
    text-align: center;
  }
`;
export const TextCss = styled.h5`
  color: ${colors.secondaryBlack1};
  margin-bottom: 0;
  padding: 0 20px;
`;
