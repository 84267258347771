import styled from 'styled-components';
import { colors, screens, transition } from '../../../../constants/theme';
export const ReviewOrderWrapperCss = styled.div`
	left: 0;
	margin: 0 auto;
	max-width: 860px;
	opacity: ${(props) => (props.active ? 1 : 0)};
	pointer-events: ${(props) => (props.active ? 'default' : 'none')};
	position: ${(props) => (props.active ? 'relative' : 'absolute')};
	top: 0;
	transition: all 0.5s ${transition};
	width: 100%;
`;

export const TitleCss = styled.h4`
	color: ${colors.secondaryBlack1};
	text-align: center;
	margin-bottom: 0;
`;

export const ProductsWrapperCss = styled.div`
	background: ${colors.secondaryWhite2};
	padding: 30px 20px;
	width: 100%;
	margin: 40px 0;
`;

export const NoMessageCss = styled.h4`
	color: ${colors.secondaryGray1};
	margin: 0;
`;

export const PreviewLineCss = styled.div`
	display: flex;
	padding-bottom: 40px;
	border-bottom: 1px solid ${colors.secondaryGray3};
	margin-bottom: 40px;

	@media (max-width: ${screens.lg}) {
		flex-direction: column;
	}
`;

export const TitleWrapperCss = styled.div`
	width: 20%;
	@media (max-width: ${screens.lg}) {
		width: 100%;
		padding: 0 20px;
		margin-bottom: 20px;
	}
`;

export const LineTitleCss = styled.p`
	color: ${colors.primaryBlack};
	margin-bottom: 10px;
`;

export const ColumnCss = styled.div`
	width: 40%;
	padding: 0 20px;
	@media (max-width: ${screens.lg}) {
		width: 100%;
		margin-bottom: 20px;
	}
`;
export const ColumnTitleCss = styled.h5`
	color: ${colors.primaryBlack};
	margin-bottom: 10px;
`;
export const ColumnTextCss = styled.h5`
	color: ${colors.secondaryGray2};
	margin: 0;
`;

export const TotalWrapperCss = styled.div`
	margin-bottom: 10px;
	max-width: 300px;
	margin-left: auto;

	@media (max-width: ${screens.lg}) {
		padding: 0 20px;
		max-width: 100%;
	}
`;

export const TotalTextCss = styled.h6`
	color: ${colors.secondaryGray1};
	margin-bottom: 10px;
	span {
		color: ${colors.primaryBlue};
	}
`;

export const SubtotalTextCss = styled.h6`
	color: ${colors.secondaryGray3};
	margin-bottom: 10px;

	span {
		color: ${colors.primaryBlue};
	}
`;
export const CouponTextCss = styled.h6`
	margin-bottom: 10px;
	color: ${colors.primaryBlue};
	cursor: pointer;
`;
export const CodeInputCss = styled.div`
	border-bottom: 1px solid ${colors.secondaryGray4};
	width: 150px;
	input {
		width: 100%;
		color: ${colors.secondaryGray3};
		padding-bottom: 10px;
		box-shadow: none;
		text-align: center;
		background: none;
		border: none;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		letter-spacing: 0.1em;
		text-transform: uppercase;
	}
	&.error-input {
		border-bottom: 1px solid ${colors.secondaryRed};
		input {
			::placeholder {
				color: ${colors.secondaryRed};
				opacity: 1;
			}
		}
	}
`;
export const CouponCodeCss = styled.div`
	display: block;
	margin-bottom: 10px;
	width: 100%;
	h5 {
		color: ${colors.secondaryRed};
	}
`;

export const LineCss = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
`;

export const GroupCss = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	justify-content: space-between;
	h6 {
		margin: 0 15px 0 0;
	}
`;

export const ButtonWrapperCss = styled.div`
	width: 200px;
	margin-left: auto;

	button {
		width: 100%;
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	@media (max-width: ${screens.lg}) {
		padding: 0 20px;
		width: 100%;
		margin-top: 15px;
	}
`;
