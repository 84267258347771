import React, { Component } from 'react';
import getCartData from '../../Shared/getCartData';
import {
	ReviewOrderWrapperCss,
	TitleCss,
	ProductsWrapperCss,
	NoMessageCss,
	PreviewLineCss,
	TitleWrapperCss,
	LineTitleCss,
	ColumnCss,
	ColumnTextCss,
	TotalWrapperCss,
	TotalTextCss,
	SubtotalTextCss,
	LineCss,
	GroupCss,
	ButtonWrapperCss,
} from './index.css';
import ProductView from '../../Cart/ProductView';
import { ChangeButtonCss } from '../AddressForm/index.css';
import { Button } from '../../../Shared';
import Discount from '../../Shared/discount';
import isProduct from '../../Shared/isProduct';
import { IShieldsDelivery, IShieldsDeliveryWrapper } from '../CartPreview/index.css';

class ReviewOrder extends Component {
	render() {
		const {
			props: {
				active,
				placeOrder,
				switchShippingView,
				switchPaymentView,
				stripeToken,
				store,
				delivery,
				leftView,
				store: {
					cart,
					shippingText,
					recurringTotal,
					orderConfirm,
					isLoggedIn,
					loadingButton,
				},
			},
		} = this;

		if (!orderConfirm) return null;
		const {
			telephone,
			email,
			shipping_address_1,
			shipping_address_2,
			shipping_city,
			shipping_country,
			shipping_postcode,
			shipping_zone,
			payment_address_1,
			payment_address_2,
			payment_city,
			payment_country,
			payment_postcode,
			payment_zone,
		} = orderConfirm;
		let last4 = null;
		let cardBrand = null;

		if (stripeToken && stripeToken.source) {
			last4 = stripeToken.source.card.last4;
			cardBrand = stripeToken.source.card.brand;
		}

		const { total, vat, subtotal } = getCartData(cart);

		return (
			<ReviewOrderWrapperCss active={active}>
				<TitleCss>Ready to place your order?</TitleCss>
				<TitleCss>Let’s make sure everything’s right.</TitleCss>

				<ProductsWrapperCss>
					{cart && cart.products ? (
						cart.products.map((product) => (
							<ProductView
								product={product}
								key={product.key}
								store={store}
								preview
							/>
						))
					) : (
						<NoMessageCss>No items in your cart</NoMessageCss>
					)}
				</ProductsWrapperCss>

				<PreviewLineCss>
					<TitleWrapperCss>
						<LineTitleCss>Shipping details</LineTitleCss>
						<ChangeButtonCss onClick={switchShippingView}>change</ChangeButtonCss>
					</TitleWrapperCss>

					{delivery === 'delivery' ? (
						<ColumnCss>
							{/* <ColumnTitleCss>Delivers to:</ColumnTitleCss> */}
							<ColumnTextCss>
								{shipping_address_1} {shipping_address_2}
							</ColumnTextCss>
							<ColumnTextCss>
								{shipping_city} {shipping_country}
							</ColumnTextCss>
							<ColumnTextCss>
								{shipping_postcode} {shipping_zone}
							</ColumnTextCss>
						</ColumnCss>
					) : (
						<ColumnCss>
							<ColumnTextCss>Bryant Dental KT16 0AW</ColumnTextCss>
							<ColumnTextCss>The Barns Hilltop</ColumnTextCss>
							<ColumnTextCss>Farm Lyne Lane Chertsey</ColumnTextCss>
						</ColumnCss>
					)}
					<ColumnCss>
						{/* <ColumnTitleCss>Contact Information:</ColumnTitleCss> */}
						<ColumnTextCss>{email}</ColumnTextCss>
						<ColumnTextCss>{telephone}</ColumnTextCss>
					</ColumnCss>
				</PreviewLineCss>

				<PreviewLineCss>
					<TitleWrapperCss>
						<LineTitleCss>Payment details</LineTitleCss>
						<ChangeButtonCss onClick={switchPaymentView}>change</ChangeButtonCss>
					</TitleWrapperCss>
					{last4 && (
						<ColumnCss>
							{/* <ColumnTitleCss>Pay with:</ColumnTitleCss> */}
							<ColumnTextCss>
								{cardBrand}: **** {last4}
							</ColumnTextCss>
						</ColumnCss>
					)}
					{isLoggedIn && (
						<ColumnCss>
							{/* <ColumnTitleCss>Billing address:</ColumnTitleCss> */}
							<ColumnTextCss>
								{payment_address_1} {payment_address_2}
							</ColumnTextCss>
							<ColumnTextCss>
								{payment_city} {payment_country}
							</ColumnTextCss>
							<ColumnTextCss>
								{payment_postcode} {payment_zone}
							</ColumnTextCss>
						</ColumnCss>
					)}
				</PreviewLineCss>

				<TotalWrapperCss>
					<LineCss>
						<SubtotalTextCss>Subtotal</SubtotalTextCss>
						<SubtotalTextCss>
							<span>{subtotal}</span>
						</SubtotalTextCss>
					</LineCss>
					{!!vat && (
						<LineCss>
							<SubtotalTextCss>VAT</SubtotalTextCss>
							<SubtotalTextCss>
								<span>{vat}</span>
							</SubtotalTextCss>
						</LineCss>
					)}

					<LineCss>
						<GroupCss>
							<SubtotalTextCss>shipping</SubtotalTextCss>
						</GroupCss>
						<SubtotalTextCss>
							<span>{shippingText || '-'}</span>
						</SubtotalTextCss>
					</LineCss>
					<Discount store={this.props.store} leftView={leftView} />
					<LineCss>
						<TotalTextCss>
							{/* Total {!shippingText ? "(without delivery)" : ""} */}
							Total
						</TotalTextCss>
						<TotalTextCss>
							<span>{total}</span>
						</TotalTextCss>
					</LineCss>
					{!!recurringTotal && (
						<LineCss>
							<TotalTextCss>Monthly</TotalTextCss>
							<TotalTextCss>
								<span>{recurringTotal}</span>
							</TotalTextCss>
						</LineCss>
					)}
					{cart && cart.products && isProduct(cart.products, 'iShields') && (
						<LineCss>
							<IShieldsDeliveryWrapper>
								<IShieldsDelivery>
									Your iShields will be delivered in approximately 21 days.
								</IShieldsDelivery>
							</IShieldsDeliveryWrapper>
						</LineCss>
					)}
				</TotalWrapperCss>
				<ButtonWrapperCss>
					<Button type="primary" revert onClick={placeOrder} loading={loadingButton}>
						place order
					</Button>
				</ButtonWrapperCss>
			</ReviewOrderWrapperCss>
		);
	}
}

export default ReviewOrder;
