// A simple wrapper that will center the content
// And keep consistent the distances
// To be used just as a container not to add colors or anything else

import React from 'react';
import styled from 'styled-components';
import {
  screens,
  globalPadding,
  globalPaddingMobile,
} from '../../../constants/theme';

const SectionWrapperCss = styled.div`
  padding: ${globalPadding} !important;
  font-size: 1rem;
  position: relative;
  display: block;
  color: white;
  height: 100%;
  ${props =>
    props.fluid &&
    `
      padding-left: 0 !important;
      padding-right: 0 !important;
    `}
  ${props =>
    props.hero &&
    `
      min-height: 100vh;
      max-height: 900px;
    `}

  @media (max-width: ${screens.lg}) {
    ${props =>
      props.hero &&
      `
        min-height: 100vh;
        height: 100%;
        max-height: auto;
    `}
  }
`;

const SectionWrapper = ({ children, fluid, hero }) => {
  return (
    <SectionWrapperCss
      hero={hero}
      className={fluid ? 'container-fluid' : 'container'}
      id="container"
      fluid={fluid}
    >
      {children}
    </SectionWrapperCss>
  );
};

export default SectionWrapper;
