import styled from 'styled-components';
import { colors, screens } from '../../../constants/theme';

export const CardListWrapperCss = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const CardWrapperCss = styled.div`
  border: 1px solid ${colors.secondaryGray2};
  display: flex;
  margin-bottom: 30px;
  width: 49%;

  @media (max-width: ${screens.lg}) {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 30px;
    flex-direction: column;

    position: ${props => (props.id === -1 ? 'relative' : 'absolute')};
    top: 0;
    left: 0;
    right: 0;
    transition: transform 1s, left 1s, opacity 1s, z-index 0s;
    z-index: ${props => (props.isCurrent ? 2 : 0)};
    transform: translate3d(-50%, 0, 0);

    left: ${props => (props.isCurrent ? '50%' : props.isNext ? '100%' : '0')};
    opacity: ${props => (props.isCurrent ? 1 : 0)};
  }
`;

export const CardImageCss = styled.div`
  flex: 1.1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.primaryBlack};
  img {
    pointer-events: none;
  }

  @media (max-width: ${screens.lg}) {
    img {
      max-height: 300px;
    }
  }
`;

export const CardContentCss = styled.div`
  background: ${colors.secondaryBlack2};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 30px;

  @media (max-width: ${screens.lg}) {
    a {
      text-align: center;
    }
  }
`;

export const CardDateWrapperCss = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const CardDateCss = styled.h6`
  color: ${colors.secondaryGray3};
  margin-bottom: 0;
  margin-right: 15px;
`;

export const CardTitleCss = styled.h4`
  color: ${colors.primaryWhite};
`;

export const CardLocationCss = styled.p`
  color: ${colors.secondaryGray3};
`;

export const CardDescriptionCss = styled.p`
  color: ${colors.secondaryGray3};
`;

export const MobileHeightCss = styled.div`
  display: none;

  @media (max-width: ${screens.lg}) {
    display: block;
    opacity: 0;
  }
`;
