import styled from 'styled-components';
import { colors, transition } from '../../../../constants/theme';

export const ViewWrapperCss = styled.div`
  left: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'default' : 'none')};
  position: ${props => (props.active ? 'relative' : 'absolute')};
  top: 0;
  transition: all 0.5s ${transition};
  width: 100%;
`;

export const AccountFormWrapperCss = styled.div`
  left: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'default' : 'none')};
  position: ${props => (props.active ? 'relative' : 'absolute')};
  top: 0;
  width: 100%;
  transition: all 0.5s ${transition};
`;

export const TitleCss = styled.h3`
  color: ${colors.secondaryBlack1};
  margin-bottom: 20px;
  text-align: center;
`;

export const FormWrapperCss = styled.div`
  background: ${colors.secondaryWhite2};
  margin-top: ${props => (props.marginTop ? '20px' : 0)};
  padding: 20px;
`;

export const SubtitleCss = styled.p`
  color: ${colors.secondaryGray2};
  text-align: center;
  padding: 0 20px;
`;

export const CreateAccountButtonCss = styled.h6`
  color: ${colors.secondaryBlue};
  text-align: center;
  margin: 40px 0 0;
  cursor: pointer;
`;
