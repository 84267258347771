import React, { Component } from 'react';

import { withRouter } from 'react-router';

import { SectionWrapper, Button } from '../../Shared';
import ProductView from './ProductView';
import getPrice from '../Shared/getPrice';
import Discount from '../Shared/discount';
import withContext from '../../../helpers/withContext';
import { ReactComponent as Cards } from './svg/cards.svg';
import { ReactComponent as Return } from './svg/return.svg';

import { ReactComponent as Amex } from './svg/amex.svg';
import { ReactComponent as Disc } from './svg/disc.svg';
import { ReactComponent as Mastercard } from './svg/mastercard.svg';
import { ReactComponent as Visa } from './svg/visa.svg';
import {
	BottomSectionCss,
	BottomSubtitleCss,
	BottomTextWrapperCss,
	BottomTitleCss,
	BoxWrapperCss,
	CartInnerWrapperCss,
	CartWrapperCss,
	ItemCountCss,
	LeftSectionCss,
	LineCss,
	LogoCss,
	LogosWrapperCss,
	NoMessageCss,
	OverViewCss,
	ProductsWrapperCss,
	RightOverviewCss,
	RightSectionCss,
	SubtotalTextCss,
	SvgWrapperCss,
	TotalCss,
	TotalTextCss,
} from './index.css';

class Cart extends Component {
	goToCheckout = () => {
		const { history } = this.props;
		history.push('/checkout');
	};

	render() {
		const {
			store,
			store: { cart, recurringTotal },
		} = this.props;
		let count = 0,
			total = 0,
			vat = 0,
			subtotal = 0;
		if (cart && cart.total) {
			[count, total] = cart.total.split(' - ');
			vat = getPrice(cart, 'vat');
			subtotal = getPrice(cart, 'sub-total');
		}

		return (
			<CartWrapperCss>
				<SectionWrapper>
					<CartInnerWrapperCss>
						<OverViewCss>
							<TotalCss>Your cart</TotalCss>
							<RightOverviewCss>
								<ItemCountCss>{!!count ? count : '0 items'}</ItemCountCss>
								<Button
									disabled={!count}
									type="primary"
									revert
									onClick={this.goToCheckout}
								>
									secure checkout
								</Button>
							</RightOverviewCss>
						</OverViewCss>

						<ProductsWrapperCss>
							{cart && cart.products ? (
								cart.products.map((product) => (
									<ProductView
										product={product}
										key={product.key}
										store={store}
									/>
								))
							) : (
								<NoMessageCss>No items in your cart</NoMessageCss>
							)}
						</ProductsWrapperCss>

						<BottomSectionCss>
							<LeftSectionCss>
								<BoxWrapperCss>
									<SvgWrapperCss>
										<Return />
									</SvgWrapperCss>
									<BottomTextWrapperCss>
										<BottomTitleCss>Easy returns</BottomTitleCss>
										<BottomSubtitleCss>
											Send it back within 45 days of receiving your order.
										</BottomSubtitleCss>
									</BottomTextWrapperCss>
								</BoxWrapperCss>

								<BoxWrapperCss>
									<SvgWrapperCss>
										<Cards />
									</SvgWrapperCss>
									<BottomTextWrapperCss>
										<BottomTitleCss>WE accept</BottomTitleCss>
										<LogosWrapperCss>
											<LogoCss>
												<Visa />
											</LogoCss>
											<LogoCss>
												<Mastercard />
											</LogoCss>
											<LogoCss>
												<Amex />
											</LogoCss>
											<LogoCss>
												<Disc />
											</LogoCss>
										</LogosWrapperCss>
									</BottomTextWrapperCss>
								</BoxWrapperCss>
							</LeftSectionCss>
							<RightSectionCss>
								<LineCss>
									<SubtotalTextCss>Subtotal</SubtotalTextCss>
									<SubtotalTextCss>{subtotal}</SubtotalTextCss>
								</LineCss>
								{!!vat && (
									<LineCss>
										<SubtotalTextCss>Vat</SubtotalTextCss>
										<SubtotalTextCss>{vat}</SubtotalTextCss>
									</LineCss>
								)}
								<Discount store={store} />

								<LineCss>
									<TotalTextCss>
										Total {/* <span>(without shipping)</span> */}
									</TotalTextCss>
									<TotalTextCss>{total}</TotalTextCss>
								</LineCss>
								{!!recurringTotal && (
									<LineCss>
										<TotalTextCss>Monthly</TotalTextCss>
										<TotalTextCss>{recurringTotal}</TotalTextCss>
									</LineCss>
								)}
								<Button
									disabled={!count}
									type="primary"
									revert
									onClick={this.goToCheckout}
								>
									secure checkout
								</Button>
							</RightSectionCss>
						</BottomSectionCss>
					</CartInnerWrapperCss>
				</SectionWrapper>
			</CartWrapperCss>
		);
	}
}

export default withRouter(withContext(Cart));
