import styled from 'styled-components';
import { colors } from '../../../constants/theme';

export const CodeInputCss = styled.div`
  border-bottom: 1px solid ${colors.secondaryGray4};
  width: 150px;
  input {
    width: 100%;
    color: ${colors.secondaryGray3};
    padding-bottom: 10px;
    box-shadow: none;
    text-align: center;
    background: none;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  &.error-input {
    border-bottom: 1px solid ${colors.secondaryRed};
    input {
      ::placeholder {
        color: ${colors.secondaryRed};
        opacity: 1;
      }
    }
  }
`;

export const CouponCodeCss = styled.div`
  display: block;
  margin-bottom: 10px;
  width: 100%;
  h5 {
    color: ${colors.secondaryRed};
  }
  button {
    h6 {
      margin: 0;
    }
  }
`;

export const CouponTextCss = styled.h6`
  margin-bottom: 10px;
  color: ${colors.primaryBlue};
  cursor: pointer;
`;

export const GroupCss = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  h6 {
    margin: 0 15px 0 0;
  }
`;

export const LineCss = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const SubtotalTextCss = styled.h6`
  color: ${colors.secondaryGray3};
  margin-bottom: 10px;

  span {
    color: ${colors.primaryBlue};
  }
`;
