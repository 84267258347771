import styled from 'styled-components';
import { colors, fonts, transition } from '../../../../constants/theme';

export const ErrorCss = styled.h5`
  color: ${colors.secondaryRed};
  margin: 5px 0;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'default' : 'none')};
`;

export const InputWrapperCss = styled.div`
  position: relative;
  width: 100%;

  input {
    background-color: transparent;
    border: none; /* CAREFUL HERE WITH THE ORDER */
    border-bottom: 1px solid
      ${props => {
        if (props.success) {
          return colors.secondartyGreen;
        } else if (props.error) {
          return colors.secondaryRed;
        }
        return colors.secondaryGray3;
      }};
    box-shadow: none;
    color: ${props =>
      props.error ? colors.secondaryRed : colors.secondaryGray2};
    font-family: ${fonts.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.1em;
    line-height: 18px;
    padding-bottom: 15px;
    padding-left: 30px;
    text-transform: uppercase;
    width: 100%;
    &::placeholder {
      color: ${colors.secondaryGray3};
      font-family: ${fonts.primary};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px ${colors.secondaryWhite2} inset;
      box-shadow: 0 0 0px 1000px ${colors.secondaryWhite2} inset;
    }
  }

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__suggestions-list {
    background: ${colors.primaryWhite};
    border: 1px solid ${colors.secondaryBlack1};
    color: ${colors.secondaryGray1};
    list-style: none;
    max-height: 200px;
    overflow: auto;
    margin-top: -1px;
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  .react-autosuggest__suggestion {
    margin: 0;
    padding: 8px 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    font-family: ${fonts.primary};
    cursor: pointer;
  }

  .react-autosuggest__suggestion--highlighted {
    background: ${colors.secondaryWhite2};
  }

  input:not(.react-autosuggest__input--focused) {
    .react-autosuggest__suggestions-list {
      display: none;
    }
  }
`;
