import styled from 'styled-components';
import { colors, transition } from '../../../../../constants/theme';
export const StripePaymentWrapperCss = styled.div``;

export const TitleWrapperCss = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  svg {
    margin-right: 10px;
  }
`;
export const TitleCss = styled.h4`
  margin: 0;
  color: ${colors.secondaryBlack1};
`;

export const CardWrapperCss = styled.div`
  padding: 20px;
  background: ${colors.secondaryWhite2};
  width: 100%;
`;

export const CartTitleCss = styled.p`
  color: ${colors.secondaryGray1};
`;

export const ButtonWrapperCss = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 20px 0;
`;

export const MessageCss = styled.p`
  margin: 20px;
  color: ${colors.secondaryGray2};
`;

export const ReturnButtonCss = styled.h6`
  color: ${colors.secondaryBlue};
  text-align: center;
`;

export const ErrorMessageCss = styled.h5`
  color: ${colors.secondaryRed};
  margin: 10px 0 0;
`;
