import styled from 'styled-components';
import { colors, screens } from '../../../../../constants/theme';

export const LoginFormWrapperCss = styled.div``;

export const ButtonWrapperCss = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 40%;
  }

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
    button {
      margin-bottom: 15px;
    }
  }
`;

export const ForgotButtonCss = styled.h6`
  color: ${colors.secondaryBlue};
  cursor: pointer;
  margin: 0;
`;

export const ErrorCss = styled.h5`
  color: ${colors.secondaryRed};
  margin: 5px 0;
`;
