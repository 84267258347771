import React, { Component } from "react";
import CreateAccountForm from "./CreateAccountForm";
import {
  ConfirmViewWrapperCss,
  TitleCss,
  TextCss,
  TextWrapperCss,
} from "./index.css";

class ConfirmView extends Component {
  render() {
    const {
      active,
      delivery,
      store,
      store: { orderId, orderConfirm, isLoggedIn },
    } = this.props;

    return (
      <ConfirmViewWrapperCss active={active}>
        <TitleCss>Thank you for your order</TitleCss>
        <TextWrapperCss>
          {orderId && <TextCss>Order number is: {orderId}</TextCss>}
          <TextCss>
            You will receive an e-mail confirmation shortly at{" "}
            {orderConfirm ? orderConfirm.email : "your email"}
          </TextCss>
        </TextWrapperCss>

        {delivery !== "delivery" && (
          <TextWrapperCss>
            <TextCss>
              Pickup store: Bryant Dental The Barns Hilltop Farm Lyne
              Lane Chertsey KT16 0AW
            </TextCss>
            <TextCss>Available: Monday - Friday 8 am - 5 pm</TextCss>
          </TextWrapperCss>
        )}
      </ConfirmViewWrapperCss>
    );
  }
}

export default ConfirmView;
