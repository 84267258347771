import styled from 'styled-components';
import { colors, screens, transition } from '../../../../constants/theme';

export const ProductWrapperCss = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.secondaryGray3};
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
  padding-bottom: 25px;
  position: relative;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  @media (max-width: ${screens.lg}) {
    justify-content: space-between;
  }
`;

export const ImageCss = styled.div`
  align-items: center;
  background-image: url(${props => props.image});
  background-position: center;
  background-size: cover;
  display: flex;
  height: 120px;
  justify-content: space-between;
  margin-right: 2%;
  overflow: hidden;
  width: 18%;

  @media (max-width: ${screens.lg}) {
    width: 100px !important;
    display: block;
    height: 100px;
    margin-right: 15px;
  }
`;

export const NameCss = styled.h4`
  color: ${colors.secondaryGray2};
  margin-right: 2%;
  margin: 0;
  width: 100%;
  @media (max-width: ${screens.lg}) {
    display: none;
  }
`;
export const MobileNameCss = styled.p`
  display: none;
  width: 150px;
  color: ${colors.secondaryGray2};
  @media (max-width: ${screens.lg}) {
    display: block;
  }
`;

export const QuantityCss = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 2%;
  position: relative;
  width: 16%;

  @media (max-width: ${screens.lg}) {
    margin: 0;
  }
`;

export const QuantityTextCss = styled.h6`
  color: ${colors.secondaryGray3};
  margin: 0 15px 0 0;

  @media (max-width: ${screens.lg}) {
    display: none;
  }
`;

export const DropdownWrapperCss = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.secondaryGray3};
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 10px auto 0;
  max-width: 220px;
  padding-bottom: 10px;
  position: relative;
  position: relative;
  width: 100%;
  z-index: ${props => (props.active ? 2 : 1)};
  @media (max-width: ${screens.lg}) {
    width: 60px;
  }
`;

export const TextCss = styled.h6`
  color: ${colors.secondaryGray3};
  margin: 0;
`;

export const ArrowWrapperCss = styled.div`
  position: absolute;
  right: 0%;
`;

export const DropdownCss = styled.div`
  background: ${colors.secondaryBlack1};
  border: 1px solid ${colors.secondaryGray3};
  left: 0;
  max-height: 200px;
  opacity: ${props => (props.active ? 1 : 0)};
  overflow: auto;
  padding: 10px 0;
  pointer-events: ${props => (props.active ? 'auto' : 'none')};
  position: absolute;
  top: 100%;
  transition: opacity 0.5s ${transition};
  width: 100%;
`;
export const OptionCss = styled.h6`
  color: ${colors.primaryWhite};
  margin: 0;
  padding: 10px 26px;
  text-align: center;
  white-space: nowrap;
  &:hover {
    /* background: ${colors.secondaryGray2}; */
    color: ${colors.secondaryBlue};
  }
`;
export const MobileWrapperCss = styled.div`
  width: 46%;
  @media (max-width: ${screens.lg}) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
`;
export const PriceCss = styled.h6`
  color: ${colors.secondaryGray2};
  margin: 0;
  text-align: right;
  width: 17%;
  @media (max-width: ${screens.lg}) {
    display: none;
  }
`;

export const MobilePriceCss = styled.h6`
  color: ${colors.secondaryGray2};
  margin: 0;
  display: none;
  @media (max-width: ${screens.lg}) {
    display: block;
  }
`;

export const SvgWrapperCss = styled.div`
  cursor: pointer;
  height: 17px;
  position: absolute;
  right: 0;
  top: 0;
  width: 17px;
`;

export const ProdOptionsCss = styled.div``;
export const ProdOptionCss = styled.p`
  color: ${colors.secondaryGray3};
  margin: 0;
`;
