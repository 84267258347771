import React, { Component } from 'react';
import {
  ChooseDeliveryWrapperCss,
  TitleCss,
  OptionsWrapperCss,
  OptionCss,
  DeliversToCss,
  InfoBoxCss,
  CircleCss,
  InfoTextCss,
  RightInfoTextCss,
  FreeCss,
  ButtonWrapperCss,
  TextMessageCss,
  ErrorMessageCss,
} from './index.css';
import { Button } from '../../../Shared';

class ChooseDelivery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      delivery: props.delivery,
    };
  }

  renderDeliveryOptions = () => {
    const { delivery } = this.state;

    if (delivery === 'delivery') {
      return (
        <>
          <TextMessageCss>
            <DeliversToCss>Delivers to:</DeliversToCss>
            <h6>UK</h6>
          </TextMessageCss>
          <InfoBoxCss>
            <CircleCss />
            <InfoTextCss>Ships next day if ordered before 12 AM</InfoTextCss>
            <FreeCss>free</FreeCss>
          </InfoBoxCss>
        </>
      );
    } else {
      return (
        <InfoBoxCss>
          <CircleCss />
          <InfoTextCss>
            Pickup store: Bryant Dental The Barns Hilltop
            <br /> Farm Lyne Lane Chertsey KT16 0AW
          </InfoTextCss>
          <RightInfoTextCss>
            Available: <br />
            Mon - Fri 8 am - 5 pm
          </RightInfoTextCss>
        </InfoBoxCss>
      );
    }
  };

  handleOnClick = () => {
    const { delivery } = this.state;
    this.props.onSubmit(delivery);
  };

  render() {
    const {
      active,
      store: { loadingButton, checkoutError },
    } = this.props;
    const { delivery } = this.state;
    return (
      <ChooseDeliveryWrapperCss active={active}>
        <TitleCss>How would you like to get your order?</TitleCss>
        <OptionsWrapperCss>
          <OptionCss
            active={delivery === 'delivery'}
            onClick={() => this.setState({ delivery: 'delivery' })}
          >
            <span>I’d like it </span>delivered
          </OptionCss>
          <OptionCss
            active={delivery === 'collect'}
            onClick={() => this.setState({ delivery: 'collect' })}
          >
            <span>I’ll </span>pick it up
          </OptionCss>
        </OptionsWrapperCss>
        {this.renderDeliveryOptions()}
        {checkoutError && <ErrorMessageCss>{checkoutError}</ErrorMessageCss>}
        <ButtonWrapperCss>
          <Button
            type="primary"
            revert
            onClick={this.handleOnClick}
            loading={loadingButton}
          >
            continue
          </Button>
        </ButtonWrapperCss>
      </ChooseDeliveryWrapperCss>
    );
  }
}
export default ChooseDelivery;
