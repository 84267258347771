import styled from 'styled-components';
import { colors, screens, transition } from '../../../../../constants/theme';

export const CreateAccountFormWrapperCss = styled.div`
  background: ${colors.secondaryWhite2};
  padding: 20px;
`;

export const TitleCss = styled.p`
  color: ${colors.secondaryGray1};
  margin-bottom: 25px;
`;

export const ButtonWrapperCss = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-top: 20px;

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
    button {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

export const ErrorCss = styled.h5`
  color: ${colors.secondaryRed};
  margin: 5px 0;
`;
