import styled from "styled-components";

export const SectionLoaderCss = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  svg {
    width: 50px;
    margin: 0 auto;
    left: 0;
    right: 0;
    position: relative;
  }
`;
