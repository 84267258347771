import React from 'react';
import { CheckboxWrapperCss, TextCss } from './index.css';

export default ({ children, checked, defaultChecked, onChange }) => (
  <CheckboxWrapperCss>
    <input
      type="checkbox"
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={onChange}
    />
    <span>
      <TextCss>{children}</TextCss>
    </span>
  </CheckboxWrapperCss>
);
