import styled from 'styled-components';
import { colors, transition } from '../../../../../constants/theme';

export const LoginFormWrapperCss = styled.div``;

export const ButtonWrapperCss = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
`;

export const ForgotButtonCss = styled.h6`
  color: ${colors.secondaryBlue};
  cursor: pointer;
  margin: 0;
`;

export const TermsCss = styled.h5`
  color: ${colors.secondaryGray2};
  margin: 25px 0 0;
  padding: 0 20px;
`;

export const LinkCss = styled.h6`
  color: ${colors.secondaryBlue};
  margin: 0 0 15px;
  padding: 0 20px;
`;

export const ErrorCss = styled.h5`
  color: ${colors.secondaryRed};
  margin: 5px 0;
  padding: 0 20px;
`;
