import React, { Component } from "react";
import MobxReactForm from "mobx-react-form";
import { fields, plugins } from "./formSetup";
import InputText from "../../InputText";
import {
  CreateAccountFormWrapperCss,
  TitleCss,
  ButtonWrapperCss,
  ErrorCss,
} from "./index.css";
import { Button } from "../../../../Shared";
// Mobx setup
const form = new MobxReactForm({ fields }, { plugins });

class CreateAccountForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registered: false,
    };
  }

  handleSignup = () => {
    const {
      store: { register, orderConfirm },
    } = this.props;
    form.submit({
      onSuccess: async (fieldset) => {
        const { password, confirmPassword } = fieldset.values();

        if (!orderConfirm) return;

        const { firstname, lastname, email, telephone } = orderConfirm;

        const registerAnswer = await register({
          firstName: firstname,
          lastName: lastname,
          email: email,
          password,
          confirmPassword,
          telephone: telephone,
        });
        if (registerAnswer === "error") return;

        this.setState({
          registered: true,
        });
      },
      onError: (fieldset) => {
        // TODO Scroll to the first field with error !
        console.log("wof", fieldset.errors());
      },
    });
  };
  render() {
    const { registered } = this.state;
    const { orderConfirm, loginError, loadingButton } = this.props.store;
    if (registered) {
      return (
        <>
          <CreateAccountFormWrapperCss>
            <TitleCss>
              Welcome aboard{" "}
              {orderConfirm ? `, ${orderConfirm.firstName}` : "!"}
            </TitleCss>
          </CreateAccountFormWrapperCss>
        </>
      );
    }
    return (
      <>
        <CreateAccountFormWrapperCss>
          <TitleCss>Save your information for next time?</TitleCss>
          <InputText field={form.$("password")} type="password" req />
          <InputText field={form.$("confirmPassword")} type="password" req />
          {loginError && <ErrorCss>{loginError}</ErrorCss>}
        </CreateAccountFormWrapperCss>
        <ButtonWrapperCss>
          <Button
            type="primary"
            revert
            onClick={this.handleSignup}
            loading={loadingButton}
          >
            Create account
          </Button>
        </ButtonWrapperCss>
      </>
    );
  }
}

export default CreateAccountForm;
