import React, { Component } from 'react';
import {
  CartProductWrapperCss,
  ImageWrapperCss,
  ProductInfoCss,
  NameCss,
  BottomInfoCss,
  QtyCss,
  PriceCss,
  NameWrapperCss,
  OptionsCss,
  OptionCss,
} from './index.css';

class CartProductView extends Component {
  render() {
    const { product } = this.props;
    if (!product) {
      return null;
    }
    const { thumb, name, quantity, total, recurring } = product;

    let price = total;

    if (recurring) {
      const [montlyPrice] = recurring.text_formatted.match(/([^\s]+)/);
      if (montlyPrice) {
        price = `${montlyPrice}/month`;
      }
    }

    return (
      <CartProductWrapperCss>
        <ImageWrapperCss>
          <img src={thumb} alt="cart-product" />
        </ImageWrapperCss>
        <ProductInfoCss>
          <NameWrapperCss>
            <NameCss
              dangerouslySetInnerHTML={{
                __html: name,
              }}
            />
            <OptionsCss>
              {product.option &&
                product.option.map(opt => {
                  if (
                    opt.name.toLowerCase() === 'pricing' ||
                    opt.name.toLowerCase() === 'engraving'
                  ) {
                    // If is pricing or engraving dont show
                    return null;
                  } else if (opt.name.toLowerCase() === 'engraving text') {
                    // if is engraving text custom display
                    return <OptionCss>Engraving: {opt.value}</OptionCss>;
                  } else {
                    // others just display the option
                    return (
                      <OptionCss>
                        {opt.name}:{' '}
                        <span dangerouslySetInnerHTML={{ __html: opt.value }} />
                      </OptionCss>
                    );
                  }
                })}
            </OptionsCss>
          </NameWrapperCss>

          <BottomInfoCss>
            <QtyCss>
              Qty: <span>{quantity}</span>
            </QtyCss>
            <PriceCss>{price}</PriceCss>
          </BottomInfoCss>
        </ProductInfoCss>
      </CartProductWrapperCss>
    );
  }
}

export default CartProductView;
