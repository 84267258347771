import styled, { css } from 'styled-components';
import { colors } from '../../../constants/theme';
const MAIN_DIRECTION_ANIMATION_DURATION = 800;

export const SHOW_CONTENT_ANIMATION_DURATION = 430;

const BUBBLE_RADIUS = '7px';

export const InfoTooltip = styled.div`
  position: absolute;
  width: 0px;
  top: ${props => props.top}%;
  left: ${props => props.left}%;
`;

export const DIRECTIONS__INFO_TOOLTIP_VERTICAL_LINE = {
  BOTTOM: 'bottom',
  TOP: 'top',
};

export const TEXT_POSITION__INFO_TOOLTIP_VERTICAL_LINE = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const InfoTooltipBubble = styled.div`
  position: absolute;
`;

export const topDirection = css``;

export const bottomDirection = css`
  top: ${BUBBLE_RADIUS};
`;

export const bottomDirectionContent = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`;

export const textLeft = css`
  right: -${BUBBLE_RADIUS};
  border-right: 1px solid ${colors.secondaryGray3};
`;

export const textRight = css`
  left: ${BUBBLE_RADIUS};
  border-left: 1px solid ${colors.secondaryGray3};
`;

export const InfoTooltipBorders = styled.div`
  position: absolute;
  height: ${props => (props.isShowContetnt ? props.length : 0)}px;
  ${props =>
    props.isShowContetnt
      ? `transition: height ${MAIN_DIRECTION_ANIMATION_DURATION}ms ease-in-out`
      : `transition: height ${MAIN_DIRECTION_ANIMATION_DURATION}ms ease-in-out ${SHOW_CONTENT_ANIMATION_DURATION}ms`};
  bottom: -${BUBBLE_RADIUS};

  ${({ direction }) => {
    switch (direction) {
      case DIRECTIONS__INFO_TOOLTIP_VERTICAL_LINE.TOP:
        return topDirection;
      case DIRECTIONS__INFO_TOOLTIP_VERTICAL_LINE.BOTTOM:
        return bottomDirection;
    }
  }}

  ${({ textPosition }) => {
    switch (textPosition) {
      case TEXT_POSITION__INFO_TOOLTIP_VERTICAL_LINE.LEFT:
        return textLeft;
      case TEXT_POSITION__INFO_TOOLTIP_VERTICAL_LINE.RIGHT:
        return textRight;
    }
  }}
`;

export const InfoTooltipContent = styled.div`
  padding: 0.5em 1em;
  ${({ direction }) => {
    if (direction === DIRECTIONS__INFO_TOOLTIP_VERTICAL_LINE.BOTTOM) {
      return bottomDirectionContent;
    }
  }}
  opacity: ${props => (props.isShowContetnt ? 1 : 0)};
  ${props =>
    props.isShowContetnt
      ? `transition: opacity ${SHOW_CONTENT_ANIMATION_DURATION}ms cubic-bezier(0.64, 0.04, 0.35, 1) ${MAIN_DIRECTION_ANIMATION_DURATION}ms`
      : `transition: opacity ${SHOW_CONTENT_ANIMATION_DURATION}ms cubic-bezier(0.64, 0.04, 0.35, 1)`};
`;
