import React, { Component } from 'react';
import MobxReactForm from 'mobx-react-form';
import { fields, plugins } from './forgetFormSetup';
import {
  ForgetFormWrapperCss,
  ButtonWrapperCss,
  SuccessMessageCss,
} from './index.css';
import { FormWrapperCss } from '../index.css';
import InputText from '../../InputText';
import { Button } from '../../../../Shared';

// Mobx setup
const form = new MobxReactForm({ fields }, { plugins });

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sent: false,
    };
  }
  handleReset = () => {
    const { forgottenPassword } = this.props.store;
    form.submit({
      onSuccess: fieldset => {
        const { email } = fieldset.values();

        const forgottenAnswer = forgottenPassword({ email });
        if (forgottenAnswer === 'error') return;
        this.setState({ sent: true });
      },
      onError: fieldset => {
        // TODO Scroll to the first field with error !
        console.log('wof', fieldset.errors());
      },
    });
  };
  render() {
    const { sent } = this.state;
    const {
      store: { forgottenLoader },
    } = this.props;
    return (
      <ForgetFormWrapperCss>
        <FormWrapperCss>
          {sent ? (
            <SuccessMessageCss>
              An email was sent to your email. Please follow the instructions
              from email to reset your password.
            </SuccessMessageCss>
          ) : (
            <InputText field={form.$('email')} req />
          )}
        </FormWrapperCss>

        <ButtonWrapperCss>
          <Button
            type="primary"
            revert
            onClick={this.handleReset}
            loading={forgottenLoader}
          >
            reset password
          </Button>
        </ButtonWrapperCss>
      </ForgetFormWrapperCss>
    );
  }
}

export default LoginForm;
