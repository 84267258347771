export default (cart, name) => {
  const answer = cart.totals.filter(tot =>
    tot.title.toLowerCase().includes(name)
  );

  if (answer && answer.length > 0) {
    return answer[0].text;
  }
  return 0;
};
