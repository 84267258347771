import React from 'react';
import { observer } from 'mobx-react';
import { ErrorCss, InputWrapperCss, InputCss } from './index.css';

export default observer(
  ({
    field,
    type = 'text',
    placeholder = null,
    handleOnChange,
    onKeyPress,
    small,
  }) => (
    <InputWrapperCss>
      <InputCss
        small={small}
        error={!!field.error}
        success={field.value && !field.error}
        aria-describedby="name-desc"
        {...field.bind({
          type,
          placeholder,
          onChange: e => {
            handleOnChange && handleOnChange(e);
            field.set(e.target.value);
          },
        })}
        onKeyPress={e => {
          if (onKeyPress && e.key === 'Enter') {
            onKeyPress();
          }
        }}
      />
      <ErrorCss active={!!field.error}>{field.error || 'NOTHING'}</ErrorCss>
    </InputWrapperCss>
  )
);
