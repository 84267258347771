import styled from 'styled-components';
import { colors, transition } from '../../../../../constants/theme';

export const ForgetFormWrapperCss = styled.div``;

export const ButtonWrapperCss = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
`;

export const ForgotButtonCss = styled.h6`
  color: ${colors.secondaryBlue};
  cursor: pointer;
  margin: 0;
`;

export const SuccessMessageCss = styled.p`
  color: ${colors.secondaryGray2};
`;
