import React, { Component } from 'react';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import ForgetForm from './ForgetForm';
import {
  ViewWrapperCss,
  AccountFormWrapperCss,
  TitleCss,
  FormWrapperCss,
  SubtitleCss,
  CreateAccountButtonCss,
} from './index.css';

class AccountForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeView: 'login',
    };
  }

  selectView = view => {
    this.setState({ activeView: view });
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  renderLogin = () => {
    const { store, switchShippingView } = this.props;
    const { activeView } = this.state;
    return (
      <ViewWrapperCss active={activeView === 'login'}>
        <TitleCss>Sign in to continue</TitleCss>
        <FormWrapperCss>
          {/* <SubtitleCss>Sign in with an E-mail</SubtitleCss> */}
          <LoginForm
            store={store}
            switchShippingView={switchShippingView}
            selectView={this.selectView}
          />
          <CreateAccountButtonCss onClick={() => this.selectView('signup')}>
            create a new account
          </CreateAccountButtonCss>
        </FormWrapperCss>
      </ViewWrapperCss>
    );
  };

  renderSignup = () => {
    const { store } = this.props;
    const { activeView } = this.state;
    return (
      <ViewWrapperCss active={activeView === 'signup'}>
        <TitleCss>Sign up</TitleCss>

        <SignupForm store={store} />
        <CreateAccountButtonCss onClick={() => this.selectView('login')}>
          already have an account?
        </CreateAccountButtonCss>
      </ViewWrapperCss>
    );
  };
  renderForgot = () => {
    const { store } = this.props;
    const { activeView } = this.state;
    return (
      <ViewWrapperCss active={activeView === 'forgot'}>
        <TitleCss>Reset your password</TitleCss>
        <SubtitleCss>
          Type in your email address below and we'll send you an email with
          instructions on how to create a new password
        </SubtitleCss>
        <ForgetForm store={store} />
        <CreateAccountButtonCss onClick={() => this.selectView('signup')}>
          create a new account
        </CreateAccountButtonCss>
      </ViewWrapperCss>
    );
  };

  render() {
    const { active } = this.props;

    return (
      <AccountFormWrapperCss active={active}>
        {this.renderLogin()}
        {this.renderSignup()}
        {this.renderForgot()}
      </AccountFormWrapperCss>
    );
  }
}

export default AccountForm;
