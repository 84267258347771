import styled from 'styled-components';
import { colors, screens } from '../../../constants/theme';

export const CartWrapperCss = styled.div`
  background: ${colors.primaryWhite};
  min-height: 100vh;

  @media (max-width: ${screens.lg}) {
    .container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
`;

export const CartInnerWrapperCss = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width: 850px;
  padding-top: 100px;
  width: 100%;

  @media (max-width: ${screens.lg}) {
    padding-top: 20px;
  }
  @media (max-width: ${screens.md}) {
    padding-top: 0px;
  }
`;

export const OverViewCss = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;
  width: 100%;

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
  }
`;

export const TotalCss = styled.h4`
  color: ${colors.secondaryBlack1};
  margin: 0;
  @media (max-width: ${screens.lg}) {
    margin-bottom: 15px;
    text-align: center;
  }
`;

export const RightOverviewCss = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${screens.lg}) {
    button {
      display: none;
    }
  }
`;

export const ItemCountCss = styled.p`
  color: ${colors.secondaryGray1};
  margin: 0 15px 0 0;
`;

export const ProductsWrapperCss = styled.div`
  background: ${colors.secondaryWhite2};
  padding: 30px 20px;
  width: 100%;
`;

export const NoMessageCss = styled.h4`
  color: ${colors.secondaryGray1};
  margin: 0;
`;

export const BottomSectionCss = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;

  @media (max-width: ${screens.lg}) {
    flex-direction: column;
  }
`;
export const LeftSectionCss = styled.div`
  width: 60%;

  @media (max-width: ${screens.lg}) {
    width: 100%;
  }
`;
export const RightSectionCss = styled.div`
  padding: 0 20px;
  width: 40%;
  button {
    margin-left: auto;
    display: block;
  }
  @media (max-width: ${screens.lg}) {
    position: sticky;
    width: 100%;
    background: ${colors.secondaryWhite2};
    padding: 20px;
    bottom: 0;
    z-index: 4;
    border-top: 1px solid ${colors.primaryWhite};
    button {
      width: 100%;
    }
  }
`;

export const BoxWrapperCss = styled.div`
  align-items: center;
  background: ${colors.secondaryWhite2};
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  padding: 20px 40px;
`;

export const SvgWrapperCss = styled.div`
  width: 80px;
  svg {
    width: 50px !important;
    height: 100%;
  }
`;

export const BottomTextWrapperCss = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-left: 10px;
`;

export const BottomTitleCss = styled.h6`
  color: black;
  margin: 0 0 10px 0;
`;

export const BottomSubtitleCss = styled.h5`
  color: ${colors.secondaryGray2};
  margin: 0;
`;

export const LogosWrapperCss = styled.div`
  align-items: center;
  display: flex;
`;
export const LogoCss = styled.div`
  margin-right: 25px;
  width: 30px;
  svg {
    width: 100%;
  }
`;
export const TotalTextCss = styled.h6`
  color: ${colors.secondaryGray1};
  span {
    color: ${colors.secondaryGray3};
  }
`;

export const SubtotalTextCss = styled.h6`
  color: ${colors.secondaryGray3};
`;

export const LineCss = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
