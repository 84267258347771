import styled from 'styled-components';
import { colors, screens } from '../../../constants/theme';

export const ButtonCss = styled.button`
  background: ${props =>
    props.type === 'primary'
      ? props.revert
        ? colors.primaryBlack
        : colors.primaryWhite
      : 'transparent'};
  color: ${props => {
    if (props.type === 'primary') {
      if (props.revert) {
        return colors.primaryWhite;
      } else {
        return colors.primaryBlack;
      }
    } else {
      if (props.revert) {
        return colors.secondaryGray3;
      } else {
        return colors.primaryWhite;
      }
    }
  }};
  border: 1px solid
    ${props => (props.revert ? colors.secondaryGray3 : colors.primaryWhite)};
  border-radius: 50px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  min-width: 100px;
  outline: none;
  overflow: hidden;
  padding: 10px 30px 9px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: color 0.1s cubic-bezier(0.16, 0.08, 0.355, 1),
    background 0.1s cubic-bezier(0.16, 0.08, 0.355, 1);
  vertical-align: middle;
  white-space: nowrap;
  width: ${({ width }) => (width ? `${width}` : 'auto')};

  h6 {
    margin: 0;
  }

  @media (max-width: ${screens.sm}) {
    width: ${({ width }) => (width ? `${width}` : 'auto')};
    padding: 6px 20px 5px;
  }

  @media (max-width: ${screens.xs}) {
    padding: 6px 20px 5px;
    letter-spacing: 1px;
  }

  @media (max-width: 400px) {
    margin: 0;
    padding: 6px 12px 5px;
    ${props => props.mobileNoPadding && 'padding: 6px 5px 5px;'};
  }

  .inner-button {
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    transition: all 300ms ease-out;
    will-change: transform;
    transform: ${props =>
      props.loading ? 'translate3d(-10px, 0, 0)' : 'translate3d(0, 0, 0)'};
    @media (max-width: ${screens.lg}) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (max-width: ${screens.xs}) {
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media (min-width: ${screens.lg}) {
    ${props => {
      if (!props.noArrow) {
        return `
          &:hover .inner-button {
            transform: translate3d(-10px, 0, 0);
          }
        `;
      }
    }}
  }

  svg {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 43%;
    transform: translateY(-50%);
    transition: all 300ms ease-out;
    width: 15px;
    will-change: right, opacity;
    * {
      stroke-width: 3;
      stroke: ${props => {
        if (props.type === 'primary') {
          if (props.revert) {
            return colors.primaryWhite;
          } else {
            return colors.primaryBlack;
          }
        } else {
          if (props.revert) {
            return colors.secondaryGray3;
          } else {
            return colors.primaryWhite;
          }
        }
      }};
    }
    &.svg-loader {
      animation: rotating 1s linear infinite;
      width: 15px;
      height: 15px;
      top: 10%;
      opacity: ${props => (props.loading ? 1 : 0)};
      right: ${props => (props.loading ? '-20px' : 0)};

      fill: ${props => {
        if (props.type === 'primary') {
          if (props.revert) {
            return colors.primaryWhite;
          } else {
            return colors.primaryBlack;
          }
        } else {
          if (props.revert) {
            return colors.secondaryGray3;
          } else {
            return colors.primaryWhite;
          }
        }
      }};

      @media (max-width: ${screens.sm}) {
        top: 0;
      }
    }
  }
  @media (min-width: ${screens.lg}) {
    ${props => {
      if (!props.noArrow) {
        return `
          &:hover svg {
            opacity: 1;
            right: -20px;
          }
        `;
      }
    }}
  }

  @keyframes rotating {
    from {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    to {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
`;
