import React, { Component } from "react";
import { ReactComponent as Arrow } from "../svg/arrow.svg";

import {
  ProductWrapperCss,
  DropdownWrapperCss,
  TextCss,
  ArrowWrapperCss,
  DropdownCss,
  OptionCss,
  ImageCss,
  NameCss,
  MobileNameCss,
  QuantityCss,
  QuantityTextCss,
  PriceCss,
  MobilePriceCss,
  MobileWrapperCss,
  SvgWrapperCss,
  ProdOptionsCss,
  ProdOptionCss,
} from "./index.css";

class ProductView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: Number(props.product.quantity) || 1,
      dropdownActive: false,
    };
  }
  componentDidMount() {
    window.addEventListener("click", this.closeDropdown);
  }
  componentWillUnmount() {
    window.removeEventListener("click", this.closeDropdown);
  }

  removeProduct = () => {
    const {
      product,
      store: { deleteCartItem },
    } = this.props;
    deleteCartItem(product);
  };

  closeDropdown = () => {
    this.setState({ dropdownActive: false });
  };

  selectQty = (e, qty) => {
    const {
      product,
      store: { updateCartItem },
    } = this.props;
    e.stopPropagation();
    this.closeDropdown();
    this.setState({
      quantity: qty,
    });

    updateCartItem(product, qty);
  };

  renderOptions = () => {
    return (
      <>
        {[...Array(20)].map((item, index) => (
          <OptionCss key={index} onClick={(e) => this.selectQty(e, index + 1)}>
            {index + 1}
          </OptionCss>
        ))}
      </>
    );
  };

  render() {
    const {
      product,
      product: { recurring },
      preview = false,
    } = this.props;
    const { dropdownActive, quantity } = this.state;

    let price = product.total;

    if (recurring) {
      const [montlyPrice] = recurring.text_formatted.match(/([^\s]+)/);
      if (montlyPrice) {
        price = `${montlyPrice}/month`;
      }
    }

    return (
      <ProductWrapperCss>
        <ImageCss image={product.thumb} />
        <MobileWrapperCss>
          <NameCss
            dangerouslySetInnerHTML={{
              __html: product.name,
            }}
          />
          <MobileNameCss
            dangerouslySetInnerHTML={{
              __html: product.name,
            }}
          />
          <ProdOptionsCss>
            {product.option &&
              product.option.map((opt) => {
                if (
                  opt.name.toLowerCase() === "pricing" ||
                  opt.name.toLowerCase() === "engraving"
                ) {
                  // If is pricing or engraving dont show
                  return null;
                } else if (opt.name.toLowerCase() === "engraving text") {
                  // if is engraving text custom display
                  return <ProdOptionCss>Engraving: {opt.value}</ProdOptionCss>;
                } else {
                  // others just display the option
                  return (
                    <ProdOptionCss>
                      {opt.name}:{" "}
                      <span dangerouslySetInnerHTML={{ __html: opt.value }} />
                    </ProdOptionCss>
                  );
                }
              })}
          </ProdOptionsCss>

          <MobilePriceCss>{price}</MobilePriceCss>
        </MobileWrapperCss>
        <QuantityCss>
          <QuantityTextCss>Qty:</QuantityTextCss>
          {preview ? (
            <TextCss>{quantity}</TextCss>
          ) : (
            <DropdownWrapperCss
              active={dropdownActive}
              onClick={(e) => {
                e.stopPropagation();
                this.setState({ dropdownActive: !dropdownActive });
              }}
            >
              <TextCss>{quantity}</TextCss>
              <ArrowWrapperCss>
                <Arrow />
              </ArrowWrapperCss>
              <DropdownCss active={dropdownActive}>
                {this.renderOptions()}
              </DropdownCss>
            </DropdownWrapperCss>
          )}
        </QuantityCss>
        <PriceCss>{price}</PriceCss>
      </ProductWrapperCss>
    );
  }
}

export default ProductView;
