import styled from "styled-components";

export const FooterCss = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  background: 0 0;
  background-color: #0c0c0c;

  #footer-widgets {
    padding: 6% 0 0;
  }

  .footer-widget {
    width: 20.875%;
    margin-right: 5.5%;
    margin-bottom: 5.5%;
    font-family: "Avenir Book", Helvetica, Arial, Lucida, sans-serif !important;
    font-size: 12px !important;
    line-height: 16px;
    float: left;
    color: #fff;
  }
  .fwidget {
    margin-bottom: 7% !important;
  }
  .et_pb_widget {
    float: left;
    max-width: 100%;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  div#custom_html-8 {
    text-align: center;
    display: block;
    width: 100%;
  }
  .column {
    float: left;
    width: 33.3%;
    padding: 0px;
    text-align: center;
  }
  ol,
  ul {
    list-style: none;
  }
  #footer-widgets .footer-widget li {
    padding-left: 14px;
    position: relative;
  }
  .et_pb_widget ul li {
    margin-bottom: 0.5em;
  }
  #footer-widgets .footer-widget li a {
    color: #fff;
    text-decoration: none;
  }

  .footer-widget .fwidget:last-child {
    margin-bottom: 0 !important;
  }
  .footer-widget:last-child {
    margin-right: 0;
  }
  .textwidget p {
    font-family: "Avenir Book", Helvetica, Arial, Lucida, sans-serif !important;

    @media (min-width: 980px) {
      font-size: 1.2em;
      line-height: 1.3em;
    }
    @media (min-width: 1200px) {
      font-size: 1.3em;
      line-height: 1.4em;
    }
    @media (min-width: 1400px) {
      font-size: 1.4em;
      line-height: 1.5em;
    }
  }
  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .phone {
    width: 25px !important;
  }
  .whatsapp {
    width: 13px !important;
  }
  .payments img {
    width: 150px;
  }

  .container {
    width: 90%;
    position: relative;
    margin: 0 auto;
  }

  @media (max-width: 980px) {
    .footer-widget:nth-child(n) {
      width: 46.25% !important;
      margin: 0 7.5% 7.5% 0 !important;
    }
    #footer-widgets .footer-widget .fwidget {
      margin-bottom: 16.21%;
    }
  }
  @media (max-width: 767px) {
    #footer-widgets {
      padding: 10% 0;
    }
    #footer-widgets .footer-widget,
    .et_pb_gutters1 #footer-widgets .footer-widget {
      width: 100% !important;
      margin-right: 0 !important;
    }
    #footer-widgets .footer-widget .fwidget,
    #footer-widgets .footer-widget:nth-child(n) {
      margin-bottom: 9.5% !important;
    }

    #footer-widgets .footer-widget .fwidget:last-child,
    #footer-widgets .footer-widget:last-child {
      margin-bottom: 0 !important;
    }
  }

  @media (max-width: 479px) {
    .footer-tag {
      font-size: 12px;
    }
    #footer-widgets {
      padding: 12% 0;
    }
    #footer-widgets .footer-widget:nth-child(n),
    .footer-widget .fwidget {
      margin-bottom: 11.5% !important;
    }
    #footer-widgets .footer-widget:nth-child(n),
    .footer-widget .fwidget {
      margin-bottom: 11.5% !important;
    }
    #footer-widgets .footer-widget:nth-child(2),
    #footer-widgets .footer-widget:nth-child(3) {
      text-align: left;
      ul {
        padding: 0;
      }
    }
    .textwidget {
      text-align: left;
      p {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .footer-tag {
      text-align: center;
    }
  }
  @media (max-width: 480px) {
    .footer-widget {
      text-align: center;
      display: block;
    }
    #footer-widgets .footer-widget:nth-child(1) .et_pb_widget {
      float: none;
    }

    #footer-widgets .footer-widget:nth-child(2),
    #footer-widgets .footer-widget:nth-child(3) {
      width: 48% !important;
      margin-right: 0 !important;
      float: left;
    }
  }
`;
