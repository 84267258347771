import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import getCartData from '../../Shared/getCartData';
import CartProductView from './CartProductView';
import {
	CartWrapperCss,
	TopRowCss,
	ItemCountCss,
	EditButtonCss,
	ProductsWrapperCss,
	NoMessageCss,
	TotalTextCss,
	SubtotalTextCss,
	LineCss,
	TotalWrapperCss,
	GroupCss,
	InfoBubbleTextCss,
	IShieldsDeliveryWrapper,
	IShieldsDelivery,
} from './index.css';

import { InfoBubble } from '../../../Shared';
import Discount from '../../Shared/discount';
import isProduct from '../../Shared/isProduct';

class CartPreview extends Component {
	render() {
		const {
			store: { cart, shippingText, recurringTotal },
			active,
			leftView,
		} = this.props;
		const { count, total, vat, subtotal } = getCartData(cart);

		return (
			<CartWrapperCss active={active}>
				<TopRowCss>
					<ItemCountCss>{count}</ItemCountCss>
					<Link to="/">
						<EditButtonCss>Edit</EditButtonCss>
					</Link>
				</TopRowCss>
				<ProductsWrapperCss>
					{cart && cart.products ? (
						cart.products.map((product) => (
							<CartProductView product={product} key={product.key} />
						))
					) : (
						<NoMessageCss>No items in your cart</NoMessageCss>
					)}
				</ProductsWrapperCss>
				<TotalWrapperCss>
					<LineCss>
						<SubtotalTextCss>Subtotal</SubtotalTextCss>
						<SubtotalTextCss>
							<span>{subtotal}</span>
						</SubtotalTextCss>
					</LineCss>
					{!!vat && (
						<LineCss>
							<GroupCss>
								<SubtotalTextCss>VAT</SubtotalTextCss>
								<InfoBubble type="info">
									<InfoBubbleTextCss>
										VAT calculated for EU countries only
									</InfoBubbleTextCss>
								</InfoBubble>
							</GroupCss>
							<SubtotalTextCss>
								<span>{vat}</span>
							</SubtotalTextCss>
						</LineCss>
					)}

					<LineCss>
						<GroupCss>
							<SubtotalTextCss>shipping</SubtotalTextCss>
							{/* <InfoBubble type="info">
								<InfoBubbleTextCss>UK: Free</InfoBubbleTextCss>
								<InfoBubbleTextCss>Europe: £20.00</InfoBubbleTextCss>
								<InfoBubbleTextCss>World wide: £35.00</InfoBubbleTextCss>
							</InfoBubble> */}
						</GroupCss>
						<SubtotalTextCss>
							<span>{shippingText || '-'}</span>
						</SubtotalTextCss>
					</LineCss>

					<Discount store={this.props.store} leftView={leftView} />

					<LineCss>
						<GroupCss>
							<TotalTextCss>
								{/* Total {!shippingText ? "(without delivery)" : ""} */}
								Total
							</TotalTextCss>
							{!vat && (
								<InfoBubble type="info">
									<InfoBubbleTextCss>
										Please be advised import duty and customs taxes will be
										applied locally
									</InfoBubbleTextCss>
								</InfoBubble>
							)}
						</GroupCss>
						<TotalTextCss>
							<span>{total}</span>
						</TotalTextCss>
					</LineCss>
					{!!recurringTotal && (
						<LineCss>
							<TotalTextCss>Monthly</TotalTextCss>
							<TotalTextCss>
								<span>{recurringTotal}</span>
							</TotalTextCss>
						</LineCss>
					)}
					{cart && cart.products && isProduct(cart.products, 'iShields') && (
						<LineCss>
							<IShieldsDeliveryWrapper>
								<IShieldsDelivery>
									Your iShields will be delivered in approximately 21 days.
								</IShieldsDelivery>
							</IShieldsDeliveryWrapper>
						</LineCss>
					)}
				</TotalWrapperCss>
			</CartWrapperCss>
		);
	}
}

export default CartPreview;
