import React, { useState, useCallback } from "react";
import { FooterCss } from "./index.css";

const Footer = () => {
  const [state, setState] = useState(1);
  const inc1 = useCallback(() => {
    setState(state + 1);
    setState(state + 1);
  }, [state]);
  const inc2 = useCallback(() => {
    setState((state) => state + 1);
    setState((state) => state + 1);
  }, []);
  console.log(state);
  return (
    <FooterCss id="main-footer">
      <div className="container">
        <div id="footer-widgets" className="clearfix">
          <div className="footer-widget">
            <div
              id="media_image-3"
              className="fwidget et_pb_widget widget_media_image"
            >
              <img
                width="281"
                height="63"
                src="https://medicairhealth.co.uk/wp-content/uploads/2020/06/medicair-white-e1593186695981.png"
                className="image wp-image-1274  attachment-full size-full"
                alt=""
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
            <div
              id="custom_html-8"
              className="widget_text fwidget et_pb_widget widget_custom_html"
            >
              <div className="textwidget custom-html-widget">
                <p className="footer-tag">
                  A <strong>BRYANT MEDICAL</strong> PRODUCT
                </p>
              </div>
            </div>
            <div
              id="media_image-6"
              className="fwidget et_pb_widget widget_media_image"
            >
              <img
                width="300"
                height="230"
                src="https://medicairhealth.co.uk/wp-content/uploads/2020/06/Reviews-V3-1-300x230.jpg"
                className="image wp-image-140  attachment-medium size-medium"
                alt=""
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
            <div
              id="custom_html-5"
              className="widget_text fwidget et_pb_widget widget_custom_html"
            >
              <div className="textwidget custom-html-widget">
                <div className="row">
                  <div className="column">
                    <a
                      href="https://en-gb.facebook.com/BryantDentalOfficial/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://medicairhealth.co.uk/wp-content/uploads/2020/06/Facebook-V3.jpg"
                        alt="Facebook"
                        style={{ width: "30%" }}
                      />
                    </a>
                  </div>
                  <div className="column">
                    <a
                      href="https://www.linkedin.com/company/bryant-dental/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://medicairhealth.co.uk/wp-content/uploads/2020/06/Linked-In.png"
                        alt="LinkedIn"
                        style={{ width: "30%" }}
                      />
                    </a>
                  </div>
                  <div className="column">
                    <a
                      href="https://www.instagram.com/bryantdental/?hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://medicairhealth.co.uk/wp-content/uploads/2020/06/Instagram.png"
                        alt="Instagram"
                        style={{ width: "30%" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-widget">
            <div
              id="nav_menu-3"
              className="fwidget et_pb_widget widget_nav_menu"
            >
              <div className="menu-main-menu-container">
                <ul id="menu-main-menu" className="menu">
                  <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-1107">
                    <a href="https://medicairhealth.co.uk" aria-current="page">
                      Home
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-458">
                    <a
                      href="https://medicairhealth.co.uk/#protection"
                      aria-current="page"
                      data-et-has-event-already="true"
                    >
                      Protection
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-460">
                    <a
                      href="https://medicairhealth.co.uk/#technology"
                      aria-current="page"
                      data-et-has-event-already="true"
                    >
                      Technology
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-459">
                    <a
                      href="https://medicairhealth.co.uk/#control"
                      aria-current="page"
                      data-et-has-event-already="true"
                    >
                      Control
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-461">
                    <a
                      href="https://medicairhealth.co.uk/#about"
                      aria-current="page"
                      data-et-has-event-already="true"
                    >
                      About us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-widget">
            <div
              id="media_image-8"
              className="fwidget et_pb_widget widget_media_image"
            >
              <img
                width="100"
                height="61"
                src="https://medicairhealth.co.uk/wp-content/uploads/2020/06/Union-Jack-BW-500-V6-300x183.png"
                className="image wp-image-106  attachment-100x61 size-100x61"
                alt=""
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          </div>
          <div className="footer-widget">
            <div id="text-4" className="fwidget et_pb_widget widget_text">
              <div className="textwidget">
                <p>
                  Bryant Dental, The Barns, Hilltop Farm, Lyne Lane, Lyne, KT16
                  0AN
                </p>
                <p>
                  MedicAir and Bryant Dental® are registered trademarks in the
                  United Kingdom and Australia.
                </p>
                <p>
                  Registered in England and Wales No. 10396020. Registered in
                  Australia No. 634 752 980
                </p>
              </div>
            </div>
            <div
              id="custom_html-3"
              className="widget_text fwidget et_pb_widget widget_custom_html"
            >
              <div className="textwidget custom-html-widget">
                <div className="social-icon">
                  <img
                    className="phone"
                    src="https://medicairhealth.co.uk/wp-content/uploads/2020/06/Phone-and-Messaging-Icons.png"
                  />{" "}
                  +44 (0) 7712 226 437
                  <br />
                  <img
                    className="whatsapp"
                    src="https://medicairhealth.co.uk/wp-content/uploads/2020/06/Whatsapp.png"
                  />{" "}
                  +44 (0) 7593 697 582
                </div>
              </div>
            </div>
            <div
              id="custom_html-6"
              className="widget_text fwidget et_pb_widget widget_custom_html"
            >
              <div className="textwidget custom-html-widget">
                <div className="payments">
                  <img
                    className="textwidget"
                    src="https://medicairhealth.co.uk/wp-content/uploads/2020/06/PaymentIcons.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FooterCss>
  );
};

export default Footer;
