import React, { Component } from 'react';

import qs from 'qs';
import axios from 'axios';
import { Provider } from './createContext';
import computeIsMobile, { getScreenSize } from '../js/isMobile';

const apiRoot = process.env.REACT_APP_STORE_API;
const apiPath = `${apiRoot}/api/rest`;
const apiPathFilter = `${apiRoot}/index.php?route=feed/rest_api`;

const mainAxios = axios.create({
	headers: {
		'Content-Type': 'application/json',
		'X-Oc-Currency': 'GBP',
		'X-Oc-Merchant-Id': 'ODUdrFUGCpx2pOrSxd6IhelaI2ge7aQV',
	},
});

const isMobile = computeIsMobile();
const screenSize = getScreenSize();
// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
class AppProvider extends Component {
	state = {
		cookie: true,
		setCookie: () => this.setCookie(),
		updateError: '',
		updateSuccess: '',
		changeUpdateError: (msg) => this.changeUpdateError(msg),
		forgottenPassword: (body) => this.forgottenPassword(body),
		resetPassword: (body) => this.resetPassword(body),
		successMessage: null,
		lang: 'en',
		changeLanguage: (lang) => this.changeLanguage(lang),
		continentCode: null,
		countryName: null,
		currency: 'GBP',
		currencySymbol: '£',
		recurringTotal: '',
		selectCurrency: (e, currency) => this.selectCurrency(e, currency),
		shippingType: null,
		setAddress: (address) => this.setAddress(address),
		returnRequest: (body) => this.returnRequest(body),
		isMobile,
		screenSize,
		loaderLoading: true,
		menuEntered: false,
		orderConfirm: null,
		checkoutError: '',
		loaded: () => this.loaded(),
		customSetState: (newState) => this.customSetState(newState),
		account: null,
		sessionId: null,
		address: null,
		billingAddress: null,
		products: null,
		loadingButton: false,
		fetchProduct: () => this.fetchProduct(),
		cart: null,
		cartChanged: false,
		isLoggedIn: false,
		loading: true,
		shippingCost: 0,
		shippingText: '£0',

		hideNavbar: false,
		toggleNavbar: () => this.toggleNavbar(),
		activateNavbar: () => this.activateNavbar(),

		getOrders: () => this.getOrders(),
		orders: null,
		getRecurrings: () => this.getRecurrings(),
		recurrings: null,
		countries: null,
		zones: null,
		billingZones: null,
		getCountries: () => this.getCountries(),
		getZones: (id, type) => this.getZones(id, type),

		addItemToCart: (body) => this.addItemToCart(body),
		getProduct: (prop, value) => this.getProduct(prop, value),
		fetchRelatedProducts: (id) => this.fetchRelatedProducts(id),
		relatedProducts: null,
		deleteCartItem: (product) => this.deleteCartItem(product),
		fetchCart: (product) => this.fetchCart(product),
		updateCartItem: (product, quantity) => this.updateCartItem(product, quantity),

		createGuest: (body) => this.createGuest(body),
		createGuestShipping: (body) => this.createGuestShipping(body),

		existingAddresses: null,
		setShippingAddressExisting: (value) => this.setShippingAddressExisting(value),
		setPaymentAddressExisting: (value) => this.setPaymentAddressExisting(value),
		setPaymentAddress: (body) => this.setPaymentAddress(body),
		getPaymentAddress: () => this.getPaymentAddress(),
		setShippingAddress: (body) => this.setShippingAddress(body),
		getShippingAddress: () => this.getShippingAddress(),

		setPaymentMethods: (body) => this.setPaymentMethods(body),
		getPaymentMethods: () => this.getPaymentMethods(),

		getShippingMethods: (method) => this.getShippingMethods(method),

		stripePayment: (token) => this.stripePayment(token),
		orderId: null,
		login: (user, pass) => this.login(user, pass),
		logout: () => this.logout(),
		register: (body) => this.register(body),
		registerError: null,
		passwordError: null,
		setPasswordError: (msg) => this.setPasswordError(msg),
		getCoupon: (code) => this.getCoupon(code),
		couponError: '',
		forgottenLoader: false,
		getInformationsArray: () => this.getInformationsArray(),
		events: null,
		tradeshows: null,
		joinTheWaitingList: (emailValue, product_id, product_url) =>
			this.joinTheWaitingList(emailValue, product_id, product_url),
	};

	componentDidMount() {
		let sessionId = null;
		const localSessionId = localStorage.getItem('sessionId');

		if (localSessionId) {
			this.initFetch(localSessionId);
		} else {
			mainAxios
				.get(`${apiPath}/session`)
				.then((response) => {
					if (response.data.data) {
						sessionId = response.data.data.session;
						localStorage.setItem('sessionId', sessionId);
						this.initFetch(sessionId);
					} else {
						sessionId = null;
					}
					this.setState({
						sessionId,
					});
				})
				.catch((error) => {
					console.log('Get session error', error);
				});
		}
	}

	// ********* Application state & functions ********* //

	changeLanguage = (lang) => {
		localStorage.setItem('lang', lang);
		this.setState({ lang });
	};

	customSetState = (newState) => {
		this.setState({
			...newState,
		});
	};

	setCookie = () => {
		this.setState({ cookie: true });
		localStorage.setItem('accept-cookie', true);
	};

	loaded = (value) => {
		if (typeof window === 'undefined') return;
		const cookie = localStorage.getItem('accept-cookie');
		this.setState({
			loaderLoading: value,
			menuEntered: !value,
		});

		setTimeout(() => {
			this.setState({ cookie });
		}, 1000);
	};

	activateNavbar = () => {
		// REQUIRED FOR INITIAL FADE IN OF NAVBAR
		this.setState({ hideNavbar: false });
	};

	toggleNavbar = () => {
		this.setState({ hideNavbar: !this.state.hideNavbar });
	};

	// ********* Endpoints ********* //

	initFetch = (sessionId) => {
		const localContinentCode = localStorage.getItem('continentCode');
		const localCountryName = localStorage.getItem('countryName');
		const lang = localStorage.getItem('lang');
		let continentCode = null;
		let countryName = null;

		const localeCurrency = localStorage.getItem('currency');
		const localeCurrencySymbol = localStorage.getItem('currencySymbol');
		const currency = localeCurrency || this.state.currency;
		const currencySymbol = localeCurrencySymbol || this.state.currencySymbol;

		if (localContinentCode && localCountryName) {
			continentCode = localContinentCode;
			countryName = localCountryName;
		} else {
			// TODO check this geoplugin if needed
			// console.log(
			//   typeof geoplugin_continentCode !== undefined,
			//   typeof geoplugin_continentCode
			// );

			// if (
			//   typeof geoplugin_continentCode !== undefined &&
			//   typeof geoplugin_continentCode !== "undefined" &&
			//   typeof geoplugin_continentCode &&
			//   geoplugin_continentCode
			// ) {
			//   continentCode = geoplugin_continentCode();
			//   countryName = geoplugin_countryName();
			// } else {
			//   continentCode = "EU";
			//   countryName = "united kingdom";
			// }
			continentCode = 'EU';
			countryName = 'united kingdom';

			localStorage.setItem('continentCode', continentCode);
			localStorage.setItem('countryName', countryName);
		}

		continentCode = 'EU';
		countryName = 'united kingdom';

		this.setState(
			{
				lang,
				currency,
				currencySymbol,
				sessionId,
				loading: false,
				continentCode,
				countryName,
			},
			async () => {
				await this.fetchProduct(sessionId, currency);
				this.fetchCart(sessionId, currency);
			}
		);
	};

	getCountries = () => {
		return mainAxios
			.get(`${apiPath}/countries`, {
				headers: { 'X-Oc-Session': this.state.sessionId },
			})
			.then((res) => {
				this.setState({ countries: res.data.data });
				return res.data.data;
			})
			.catch((err) => {
				return 'error';
			});
	};

	getZones = (id, type) => {
		return mainAxios
			.get(`${apiPath}/countries/${id}`, {
				headers: { 'X-Oc-Session': this.state.sessionId },
			})
			.then((res) => {
				if (type === 'shipping') {
					this.setState({ zones: res.data.data.zone });
					return res.data.data.zone;
				} else if (type === 'billing') {
					this.setState({ billingZones: res.data.data.zone });
					return res.data.data.zone;
				} else {
					return 'error';
				}
			})
			.catch((err) => {
				return 'error';
			});
	};

	selectCurrency = (e, currency) => {
		let currencySymbol = '';
		e.stopPropagation();
		if (currency === 'GBP') {
			currencySymbol = '£';
		} else if (currency === 'EUR') {
			currencySymbol = '€';
		} else if (currency === 'USD') {
			currencySymbol = '$';
		} else {
			currencySymbol = '';
		}

		localStorage.setItem('currency', currency);
		localStorage.setItem('currencySymbol', currencySymbol);
		this.setState(
			{
				currency,
				currencySymbol,
			},
			() => {
				this.fetchProduct(null, currency);
				this.fetchCart(null, currency);
			}
		);
	};

	// ********* USER ********* //
	catchNotLoggedIn = (err) => {
		if (
			err &&
			err.response &&
			err.response.data &&
			err.response.data.error &&
			err.response.data.error[0] === 'User is not logged in'
		) {
			this.setState({ isLoggedIn: false, account: [] });
		}
	};

	changeUpdateError = (msg) => {
		this.setState({
			updateError: msg,
		});
	};

	setPasswordError = (msg = null) => {
		this.setState({ passwordError: msg });
	};

	forgottenPassword = (body) => {
		this.setState({
			successMessage: null,
			forgottenLoader: true,
			loginError: null,
		});
		return mainAxios
			.post(`${apiPath}/forgotten`, body, {
				headers: { 'X-Oc-Session': this.state.sessionId },
			})
			.then((res) => {
				this.setState({
					forgottenLoader: false,
					successMessage: 'Success, you will receive next steps on email.',
				});
				return 'success';
			})
			.catch((error) => {
				const errorMessage = this.getErrorMessage(error);
				this.setState({
					forgottenLoader: false,
					loginError: errorMessage,
				});
				return 'error';
			});
	};

	resetPassword = (body) => {
		this.setState({
			forgottenLoader: true,
		});
		return mainAxios
			.post(`${apiPath}/reset`, body, {
				headers: { 'X-Oc-Session': this.state.sessionId },
			})
			.then((res) => {
				this.setState({
					forgottenLoader: false,
				});
				return 'success';
			})
			.catch((error) => {
				const errorMessage = this.getErrorMessage(error);
				this.setState({
					forgottenLoader: false,
					loginError: errorMessage,
				});
				return 'error';
			});
	};

	login = (email, pass) => {
		this.setState({
			loadingButton: true,
			loginError: '',
			successMessage: null,
		});
		const body = {
			email: email,
			password: pass,
		};
		return mainAxios
			.post(`${apiPath}/login`, body, {
				headers: { 'X-Oc-Session': this.state.sessionId },
			})
			.then((res) => {
				this.setState({
					isLoggedIn: true,
					account: res.data.data,
					loadingButton: false,
				});
				this.fetchCart();
				this.getShippingAddress();
				return 'success';
			})
			.catch((err) => {
				const errorMessage = this.getErrorMessage(err);
				this.setState({
					loginError: errorMessage,
					loadingButton: false,
				});
				return 'error';
			});
	};

	logout = () => {
		mainAxios
			.post(
				`${apiPath}/logout`,
				{},
				{
					headers: { 'X-Oc-Session': this.state.sessionId },
				}
			)
			.then((res) => {
				this.setState({
					isLoggedIn: false,
					cart: null,
					orders: null,
					recurrings: null,
					existingAddresses: null,
				});
			})
			.catch((err) => {
				console.log('Logout', err);
			});
	};

	register = ({ firstName, lastName, email, password, confirmPassword, telephone }) => {
		this.setState({
			loadingButton: true,
			successMessage: null,
			registerError: null,
		});
		const body = {
			firstname: firstName,
			lastname: lastName,
			email,
			password,
			confirm: confirmPassword,
			telephone,
			agree: '1',
		};
		return mainAxios
			.post(`${apiPath}/register`, body, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				const account = {
					firstname: res.data.data.firstname,
					lastname: res.data.data.lastname,
				};
				this.setState({
					isLoggedIn: true,
					account: res.data.data,
					loadingButton: false,
					registerError: null,
				});
				return account;
			})
			.catch((err) => {
				const errorMessage = this.getErrorMessage(err);
				this.setState({ registerError: errorMessage, loadingButton: false });
				return 'error';
			});
	};

	// ********* PRODUCTS ********* //

	fetchProduct = async (sessionId = null, currency = null) => {
		return await mainAxios
			.get(`${apiPath}/products/${process.env.REACT_APP_PRODUCT_ID}`, {
				headers: {
					'X-Oc-Session': sessionId || this.state.sessionId,
					'X-Oc-Currency': currency || this.state.currency,
				},
			})
			.then((response) => {
				const products = response.data && response.data.data;

				if (typeof products !== 'object') return;
				this.setState({
					products,
				});
			})
			.catch((error) => {
				this.setState({ products: [] });
				console.log('Fetch products error', error);
			});
	};

	// Gets the product from url or product id
	// Prop: url / productId
	getProduct = (prop, value) => {
		const { products } = this.state;
		if (!products) return null;
		let product = null;
		if (prop === 'url') {
			// const url = value.split('/')[2];
			product = products.filter((prod) => prod.seo_url === value);
			return product[0];
		}
		if (prop === 'id') {
			product = products.filter(
				(prod) => parseInt(prod.product_id, 10) === parseInt(value, 10)
			);
			return product[0];
		}
		if (prop === 'name') {
			let StoreItem = products.filter((product) => {
				if (value === 'Hive Edition') {
					return product.name === 'CFlo Hive Edition';
				}
				return product.name.toLowerCase().includes(value.toLowerCase());
			});
			console.log(StoreItem);
			console.log(prop);
			console.log(value);
			if (StoreItem[0]) {
				return StoreItem[0];
			} else {
				return null;
			}
		}

		return null;
	};

	fetchRelatedProducts = (id, sessionId = null, currency = null) => {
		mainAxios
			.get(`${apiPathFilter}/related&id=${id}`, {
				headers: {
					'X-Oc-Session': sessionId || this.state.sessionId,
					'X-Oc-Currency': currency || this.state.currency,
				},
			})
			.then((response) => {
				const relatedProducts = response.data && response.data.data;

				if (typeof relatedProducts !== 'object') return;
				this.setState({
					relatedProducts,
				});
			})
			.catch((error) => {
				this.setState({ relatedProducts: [] });
				console.log('Fetch products error', error);
			});
	};

	returnRequest = (body) => {
		this.setState({ loadingButton: true });
		return mainAxios
			.post(`${apiPath}/returns`, body, {
				headers: { 'X-Oc-Session': this.state.sessionId },
			})
			.then((response) => {
				this.setState({ loadingButton: false });
				return 'success';
			})
			.catch((error) => {
				this.setState({ loadingButton: false });
				return 'error';
			});
	};

	joinTheWaitingList = (emailValue, product_id, product_url) => {
		const body = {
			name: 'waiting_user',
			email: emailValue,
			message: '',
			product_id: product_id,
			product_url: product_url,
		};
		// console.log(body);
		return mainAxios.post(`${apiPath}//back-in-stock-notification-registration`, body, {
			headers: {
				'X-Oc-Session': this.state.sessionId,
				'X-Oc-Currency': this.state.currency,
				'Content-Type': 'application/json',
			},
		});
	};

	// ********* CART ********* //
	getShippingText = (cart) => {
		if (!cart) return null;
		const shipping = cart.totals.filter((total) =>
			total.title.toLowerCase().includes('shipping')
		);

		return shipping.length > 0 ? shipping[0].text : null;
	};

	fetchCart = (sessionId = null, currency = null) => {
		return mainAxios
			.get(`${apiPath}/cart`, {
				headers: {
					'X-Oc-Session': sessionId || this.state.sessionId,
					'X-Oc-Currency': currency || this.state.currency,
				},
			})
			.then((response) => {
				let cart = response.data && response.data.data;
				if (!cart || cart.length === 0) cart = null;

				// Mapping on the products array and calculating the sum for recurring products
				let recurringTotal = 0;
				if (cart && cart.products && cart.products.length > 0) {
					cart.products.map((prod) => {
						if (prod.recurring) {
							const values = prod.recurring.text_formatted
								.match(/[0-9]*\.?[0-9]*/g)
								.filter((e) => !!e);

							recurringTotal = recurringTotal + parseFloat(values[0]);
						}
					});
				}
				// After we got the total if not 0 then add the currency symbol
				if (recurringTotal !== 0) {
					recurringTotal =
						this.state.currencySymbol !== '€'
							? `${this.state.currencySymbol}${recurringTotal.toFixed(2)}`
							: `${recurringTotal.toFixed(2)}${this.state.currencySymbol}`;
				}

				const shippingText = this.getShippingText(cart);
				if (!cart) {
					this.addItemToCart({ product: this.state.products, quantity: 1 });
				}
				this.setState({
					cart,
					cartChanged: !this.state.cartChanged,
					shippingText,
					recurringTotal,
				});
			})
			.catch((error) => {
				console.log('Fetch cart error', error);
				return 'error';
			});
	};

	addItemToCart = ({ product, quantity, options, recurring }) => {
		console.log('addItemToCart', product);
		const body = {
			product_id: product.id,
			quantity,
			option: options,
			recurring_id: recurring,
		};

		return mainAxios
			.post(`${apiPath}/cart`, body, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then(() => {
				this.fetchCart();
				return { type: 'success' };
			})
			.catch((err) => {
				return { type: 'error', err };
			});
	};

	updateCartItem = (product, quantity) => {
		const body = {
			key: product.key,
			quantity,
		};
		mainAxios
			.put(`${apiPath}/cart`, body, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then(() => {
				this.fetchCart();
			})
			.catch((err) => {
				console.log('Update item error', err, product);
			});
	};

	deleteCartItem = (product) => {
		mainAxios
			.delete(`${apiPath}/cart/${product.key}`, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then(() => {
				this.fetchCart();
			})
			.catch((err) => {
				console.log('Delete item error', err, product);
			});
	};

	emptyCart = () => {
		mainAxios
			.delete(`${apiPath}/cart/empty`, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.catch((err) => {
				console.log('Empty cart error', err);
			});
	};

	// ********* CHECKOUT ********* //
	getErrorMessage = (err) => {
		let errorMessage =
			(err &&
				err.response &&
				err.response.data &&
				err.response.data.error &&
				err.response.data.error.length > 0 &&
				err.response.data.error[0]) ||
			'Oops! Something went wrong. Please refresh and try again.';

		return errorMessage;
	};

	createGuest = (body) => {
		this.setState({ loadingButton: true, checkoutError: null });
		return mainAxios
			.post(`${apiPath}/guest`, body, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				return res;
			})
			.catch((err) => {
				const errorMessage = this.getErrorMessage(err);
				this.setState({ loadingButton: false, checkoutError: errorMessage });
				return 'error';
			});
	};

	createGuestShipping = (body) => {
		return mainAxios
			.post(`${apiPath}/guestshipping`, body, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then(() => {
				this.setState({ address: body });
				return 'success';
			})
			.catch((err) => {
				const errorMessage = this.getErrorMessage(err);
				this.setState({ loadingButton: false, checkoutError: errorMessage });
				return 'error';
			});
	};

	getPaymentAddress = () => {
		return mainAxios
			.get(`${apiPath}/paymentaddress`, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				console.log('Get payment address', res);
				return res.data;
			})
			.catch((err) => {
				console.log('Get payment address', err);
			});
	};

	setPaymentAddress = (body) => {
		this.setState({ loadingButton: true, address: body });
		return mainAxios
			.post(`${apiPath}/paymentaddress`, body, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				this.setState({
					billingAddress: body,
				});
				return res.data.data ? res.data.data.address_id : 'error';
			})
			.catch((err) => {
				const msg = err.response.data.error[0];
				this.setState({ checkoutError: msg, loadingButton: false });
				return 'error';
			});
	};

	setPaymentAddressExisting = (value) => {
		const body = { address_id: value };
		this.setState({ loadingButton: true });
		return mainAxios
			.post(`${apiPath}/paymentaddress/existing`, body, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then(() => {
				return 'success';
			})
			.catch((err) => {
				const msg = err.response.data.error[0];
				this.setState({ checkoutError: msg, loadingButton: false });
				return 'error';
			});
	};

	getShippingAddress = () => {
		return mainAxios
			.get(`${apiPath}/shippingaddress`, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				this.setState({ existingAddresses: res.data.data.addresses });
				return res.data;
			})
			.catch((err) => {
				console.log('Get Shipping address', err);
			});
	};

	setShippingAddress = (body) => {
		return mainAxios
			.post(`${apiPath}/shippingaddress`, body, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				this.setState({ address: body });
				return res.data.data ? res.data.data.address_id : 'error';
			})
			.catch((err) => {
				const msg = err.response.data.error[0];
				this.setState({ checkoutError: msg, loadingButton: false });
				return 'error';
			});
	};

	setShippingAddressExisting = (value) => {
		const body = { address_id: value };
		return mainAxios
			.post(`${apiPath}/shippingaddress/existing`, body, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				return 'success';
			})
			.catch((err) => {
				const msg = err.response.data.error[0];
				this.setState({ checkoutError: msg, loadingButton: false });
				return 'error';
			});
	};

	getPaymentMethods = () => {
		this.setState({ loadingButton: true });
		return mainAxios
			.get(`${apiPath}/paymentmethods`, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				const { data } = res.data;
				const method = Object.keys(data.payment_methods)[0];
				const code = data.payment_methods[method].code;
				return this.setPaymentMethods(code);
			})
			.catch((err) => {
				let errorMessage =
					err.response.data &&
					err.response.data.error &&
					err.response.data.error.length > 0 &&
					err.response.data.error[0];

				if (errorMessage) {
					errorMessage = errorMessage.split('Warning: ')[1];
					errorMessage = errorMessage.split('Please')[0];
				}
				this.setState({
					checkoutError: errorMessage,
					loadingButton: false,
				});
			});
	};

	setPaymentMethods = (code) => {
		const body = {
			payment_method: code,
			agree: '1',
			comment: '',
		};
		return mainAxios
			.post(`${apiPath}/paymentmethods`, body, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				return this.confirmCheckout();
			})
			.catch((err) => {
				let errorMessage =
					err.response &&
					err.response.data &&
					err.response.data.error &&
					err.response.data.error.length > 0 &&
					err.response.data.error[0];

				if (errorMessage) {
					errorMessage = errorMessage.split('Warning: ')[1];
					errorMessage = errorMessage.split('Please')[0];
				}
				this.setState({
					checkoutError: errorMessage,
					loadingButton: false,
				});
			});
	};

	confirmCheckout = () => {
		return mainAxios
			.post(
				`${apiPath}/confirm`,
				{},
				{
					headers: {
						'X-Oc-Session': this.state.sessionId,
						'X-Oc-Currency': this.state.currency,
					},
				}
			)
			.then((res) => {
				this.setState({
					orderConfirm: res.data.data,
					loadingButton: false,
				});
				return 'success';
			})
			.catch((err) => {
				let errorMessage =
					err.response.data &&
					err.response.data.error &&
					err.response.data.error.length > 0 &&
					err.response.data.error[0];

				if (errorMessage) {
					errorMessage = errorMessage.split('Warning: ')[1];
					errorMessage = errorMessage.split('Please')[0];
				}
				this.setState({
					checkoutError: errorMessage,
					loadingButton: false,
				});
			});
	};

	payCheckout = () => {
		mainAxios
			.get(`${apiPath}/pay`, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				console.log('Pay', res);
			})
			.catch((err) => {
				console.log('Pay', err);
			});
	};

	getShippingMethods = (shippingType) => {
		return mainAxios
			.get(`${apiPath}/shippingmethods`, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				this.setState({ shippingType });
				let methodCode;
				const methods = res.data && res.data.data;

				if (!methods) {
					this.setState({
						loadingButton: false,
						checkoutError: 'No shipping available. Please try again later.',
					});
					return 'error';
				}
				if (shippingType === 'collect') {
					if (
						methods.shipping_methods &&
						methods.shipping_methods.pickup &&
						methods.shipping_methods.pickup.quote &&
						methods.shipping_methods.pickup.quote.pickup
					) {
						methodCode = methods.shipping_methods.pickup.quote.pickup.code;
						this.setState({
							shippingCost: 0,
							shippingText: '£0',
							shippingTitle: 'collect',
							continentCode: 'EU',
						});
					}
				} else if (shippingType === 'delivery') {
					if (
						methods.shipping_methods &&
						methods.shipping_methods.cs &&
						methods.shipping_methods.cs.quote
					) {
						const { quote } = methods.shipping_methods.cs;
						const key = Object.keys(quote)[0];
						if (quote[key]) {
							const continentCode =
								quote[key].title.includes('EU') || quote[key].title.includes('UK')
									? 'EU'
									: null;
							methodCode = quote[key].code;
							console.log(continentCode, methodCode);
							// Set code in local storage
							localStorage.setItem('continentCode', continentCode);

							this.setState({
								shippingCost: quote[key].cost,
								shippingText: quote[key].text,
								continentCode,
							});
						}
					}
				}

				return this.setShippingMethods(methodCode);
			})
			.catch((err) => {
				const errorMessage = this.getErrorMessage(err);
				this.setState({ loadingButton: false, checkoutError: errorMessage });
			});
	};

	setShippingMethods = (shippingMethod) => {
		const body = {
			shipping_method: shippingMethod,
			comment: '',
		};
		return mainAxios
			.post(`${apiPath}/shippingmethods`, body, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then(async (res) => {
				await this.fetchCart();
				return res.data;
			})
			.catch((err) => {
				const errorMessage = this.getErrorMessage(err);
				this.setState({ loadingButton: false, checkoutError: errorMessage });
			});
	};

	// ********* PAYMENT ********* //

	stripePayment = ({ source }) => {
		this.setState({ loadingButton: true });
		const body = {
			token: source.id,
			type: 'card',
			reusable: 1,
		};
		return mainAxios
			.post(
				`${apiRoot}/index.php?route=extension/payment/advertikon_stripe/order`,
				qs.stringify(body),
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'X-Oc-Session': this.state.sessionId,
						'X-Oc-Currency': this.state.currency,
					},
				}
			)
			.then((res) => {
				this.setState({ loadingButton: false });
				//  if res is success then call clearcart
				// TODO display some error messages somewhere
				if (!res.data.success) {
					this.setState({ checkoutError: 'Ops. Something went wrong!!!' });
					return 'error';
				}
				return this.clearCart();
			})
			.catch((err) => {
				this.setState({
					loadingButton: false,
					checkoutError: 'Ops. Something went wrong!!! Please contact Razvan',
				});
			});
	};

	setAddress = (address) => {
		this.setState({ address });
	};

	clearCart = () => {
		return mainAxios
			.put(
				`${apiPath}/confirm`,
				{},
				{
					headers: {
						'X-Oc-Session': this.state.sessionId,
						'X-Oc-Currency': this.state.currency,
					},
				}
			)
			.then((res) => {
				this.setState({
					cart: null,
					orderId: res.data.data.order_id,
				});
				return res.data.data.order_id;
			})
			.catch((err) => {
				this.setState({
					loadingButton: false,
					checkoutError: 'Ops. Something went wrong!!!',
				});
			});
	};

	getCoupon = (code) => {
		return mainAxios
			.post(
				`${apiPath}/coupon`,
				{ coupon: code },
				{
					headers: {
						'X-Oc-Session': this.state.sessionId,
						'X-Oc-Currency': this.state.currency,
					},
				}
			)
			.then((res) => {
				this.fetchCart();
				this.setState({ couponError: '' });
				return res.data;
			})
			.catch((err) => {
				const msg = err.response && err.response.data && err.response.data.error[0];
				this.setState({ couponError: msg || 'Ops!!! Something went wrong!' });
				return 'error';
			});
	};

	// ********* ORDERS ********* //
	getOrders = () => {
		return mainAxios
			.get(`${apiPath}/customerorders/limit/100/page/1`, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				console.log('orders res', res);
				this.setState({ orders: res.data.data });
				return res.data;
			})
			.catch((err) => {
				console.log('orders error', err);
				return 'error';
			});
	};
	getOrderDetails = () => {
		return mainAxios
			.get(`${apiPath}/customerorders/limit/100/page/1`, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				console.log('orders res', res);
				this.setState({ orders: res.data.data });
				return res.data;
			})
			.catch((err) => {
				console.log('orders error', err);
				return 'error';
			});
	};
	getRecurrings = () => {
		return mainAxios
			.get(`${apiPath}/account/recurrings`, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				this.setState({ recurrings: res.data.data });
				return res.data;
			})
			.catch((err) => {
				console.log('reccurings error', err);
				return 'error';
			});
	};

	/********* INFORMATION ********/
	JSONify = (input) => {
		const toChange = [
			{ from: '/p&gt;', to: '' },
			{ from: 'p&gt;', to: '' },
			{ from: '&lt;', to: '' },
			{ from: '&amp;nbsp;', to: '' },
			{ from: 'br&gt;', to: '' },
			{ from: '&quot;', to: '"' },
			{ from: '&amp;amp;', to: '&' },
		];
		let res = input;

		for (let match of toChange) {
			res = res.replace(new RegExp(match.from, 'g'), match.to);
		}

		return JSON.parse(res);
	};

	getInformation = (field, id) => {
		return mainAxios
			.get(`${apiPath}/information/${id}`, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				const eventInfo = res.data.data;
				if (!eventInfo) return;

				const descriptionObject = this.JSONify(eventInfo.description);
				this.setState({
					[field]: descriptionObject,
				});
			})
			.catch((err) => {
				console.log('information error', err);
				return 'error';
			});
	};

	getInformationsArray = () => {
		return mainAxios
			.get(`${apiPath}/information`, {
				headers: {
					'X-Oc-Session': this.state.sessionId,
					'X-Oc-Currency': this.state.currency,
				},
			})
			.then((res) => {
				const allInfo = res.data.data;
				// Grabs all events and we filter to get only the events
				const [events] = allInfo.filter((info) => info.title.toLowerCase() === 'events');
				const [tradeshows] = allInfo.filter(
					(info) => info.title.toLowerCase() === 'tradeshows'
				);
				const [courses] = allInfo.filter((info) => info.title.toLowerCase() === 'courses');
				if (events) {
					this.getInformation('events', events.id);
				}
				if (tradeshows) {
					this.getInformation('tradeshows', tradeshows.id);
				}
				if (courses) {
					this.getInformation('courses', courses.id);
				}
			})
			.catch((err) => {
				console.log('information error', err);
				return 'error';
			});
	};

	render() {
		return <Provider value={this.state}>{this.props.children}</Provider>;
	}
}

export default AppProvider;
