import React, { Component } from 'react';
import {
  CodeInputCss,
  CouponCodeCss,
  CouponTextCss,
  GroupCss,
  LineCss,
  SubtotalTextCss,
} from './index.css';
import { Button } from '../../Shared';
import getCartData from './getCartData';

class Discount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponActive: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ couponCode: event.target.value });
  }

  openCoupon = () => {
    this.setState({ couponActive: true });
  };

  setCoupon = coupon => {
    const { getCoupon } = this.props.store;
    getCoupon(coupon);
  };

  render() {
    const {
      state: { couponActive, couponCode },
      props: {
        store: { couponError, cart },
        leftView,
      },
      openCoupon,
      setCoupon,
      handleChange,
    } = this;

    const { discount } = getCartData(cart);

    if (discount) {
      return (
        <LineCss>
          <GroupCss>
            <SubtotalTextCss>Discount </SubtotalTextCss>
          </GroupCss>
          <SubtotalTextCss>
            <span>-{discount.replace('-', '')}</span>
          </SubtotalTextCss>
        </LineCss>
      );
    }
    // If is leftView exists and is shipping
    // Do not display add coupon option
    if (leftView && leftView !== 'shipping') {
      return null;
    }
    return (
      <LineCss>
        {!couponActive ? (
          <GroupCss>
            <CouponTextCss onClick={() => openCoupon()}>
              got a coupon code?
            </CouponTextCss>
          </GroupCss>
        ) : (
          <CouponCodeCss>
            <GroupCss>
              <CodeInputCss className={couponError ? 'error-input' : ''}>
                <input
                  type="text"
                  placeholder="YOUR CODE"
                  value={couponCode}
                  onChange={handleChange}
                />
              </CodeInputCss>
              <Button
                type="secondary"
                revert
                onClick={() => setCoupon(couponCode)}
              >
                submit
              </Button>
            </GroupCss>
            {couponError && <h5>Invalid code. Try again.</h5>}
          </CouponCodeCss>
        )}
      </LineCss>
    );
  }
}
export default Discount;
