import getPrice from './getPrice';

export default cart => {
  let count = '0 Items',
    total = 0,
    vat = 0,
    discount,
    subtotal = 0;

  if (cart && cart.total) {
    [count, total] = cart.total.split(' - ');
    vat = getPrice(cart, 'vat');
    subtotal = getPrice(cart, 'sub-total');
    discount = getPrice(cart, 'coupon');
  }

  return {
    count,
    total,
    vat,
    subtotal,
    discount,
  };
};
