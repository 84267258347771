import styled from 'styled-components';
import { colors, screens, fonts } from '../../../constants/theme';

export const InfoWrapperCss = styled.div`
  background: ${colors.secondaryGray3};
  transform: ${props => (props.center ? 'translateY(25%)' : '')};
  margin-top: ${props => (props.center ? '-25%' : '')};
  border-radius: 50%;
  cursor: default;
  display: inline-block;
  height: 25px;
  position: relative;
  width: 25px;
  .lil-info {
    color: ${colors.primaryBlack};
    font-family: ${fonts.primary};
    font-size: 18px;
    font-weight: 500;
    left: 50%;
    margin-top: 1px;

    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ButtonWrapperCss = styled.div`
  cursor: default;
  display: inline-block;
  position: relative;

  .bubble-button-text {
    color: ${colors.primaryBlue};
    cursor: default;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
`;

export const OverlayCss = styled.div`
  @media (max-width: ${screens.md}) {
    pointer-events: none;
    ${props =>
      props.ready && props.active
        ? 'opacity:0.7; z-index: 101;'
        : 'opacity:0;z-index: 1;'}
    transition: opacity 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colors.primaryBlack};
  }
`;

export const MessageCss = styled.div`
  background: black;
  border: 1px solid;
  max-width: 100%;
  max-height: 40vh;
  border-color: ${props =>
    props.ready && props.active ? colors.secondaryGray3 : 'transparent'};
  border-width: ${props => (props.ready && props.active ? '1px' : '0px')};
  box-sizing: content-box;
  overflow: auto;
  position: absolute;
  width: 300px;
  max-width: calc(100vw - 10px);
  z-index: 999;
  transition: opacity 0.4s linear, height 0.4s ease-in-out;
  ${props =>
    props.active
      ? 'opacity: 1;' + 'height:' + props.ready
        ? 'fit-content;'
        : '0px;'
      : 'pointer-events:none;' + 'opacity:0;' + 'height:auto;'}

  @media (max-width: ${screens.lg}) {
    z-index: 110;
  }
  @media (max-width: ${screens.sm}) {
    max-height: 70vh;
    pointer-events: all;
    ${props => !props.active && 'height:0px;'}
  }
  ${props => props.position}
`;

export const MessageInnerCss = styled.div`
  padding: 15px;

  h6,
  p {
    margin: 5px 0;
    color: ${colors.secondaryGray3};
    font-family: ${fonts.primary};
  }
  p {
    font-weight: 300;
    font-size: 21px;
    line-height: 28px;
    white-space: pre-line;
    @media (max-width: ${screens.lg}) {
      font-size: 19px;
      line-height: 24px;
    }
  }
  h6 {
    letter-spacing: 3px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    @media (max-width: ${screens.lg}) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;
