import styled from 'styled-components';
import { colors, fonts, transition } from '../../../../constants/theme';

export const ErrorCss = styled.h5`
  color: ${colors.secondaryRed};
  margin: 5px 0;
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'default' : 'none')};
`;

export const InputWrapperCss = styled.div`
  position: relative;
  width: 100%;
`;

export const InputCss = styled.input`
  background-color: transparent;
  border: none; /* CAREFUL HERE WITH THE ORDER */
  border-bottom: 1px solid
    ${props => {
      if (props.success) {
        return colors.secondartyGreen;
      } else if (props.error) {
        return colors.secondaryRed;
      }
      return colors.secondaryGray3;
    }};
  box-shadow: none;
  color: ${props =>
    props.error ? colors.secondaryRed : colors.secondaryGray2};
  font-family: ${fonts.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.1em;
  line-height: 18px;
  padding-bottom: 15px;
  padding-left: 30px;
  text-transform: uppercase;
  width: ${props => (props.small ? '50%' : '100%')};
  &::placeholder {
    color: ${colors.secondaryGray3};
    font-family: ${fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px ${colors.secondaryWhite2} inset;
    box-shadow: 0 0 0px 1000px ${colors.secondaryWhite2} inset;
  }
`;
