import validatorjs from 'validatorjs';

const plugins = { dvr: validatorjs };

const fields = [
  {
    name: 'firstName',
    placeholder: 'First name',
    rules: 'required|string',
  },
  {
    name: 'lastName',
    placeholder: 'Last name',
    rules: 'required|string',
  },
  {
    name: 'telephone',
    placeholder: 'phone number',
    rules: 'required|numeric',
  },
  {
    name: 'email',
    placeholder: 'E-mail',
    rules: 'required|email|string',
  },
  {
    name: 'password',
    placeholder: 'password',
    rules: 'required',
  },
  {
    name: 'confirmPassword',
    placeholder: 'confirm password',
    rules: 'required|same:password',
  },
];

export { plugins, fields };
